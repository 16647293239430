export default [
    {
        'year': 1,
        'header': 'Seed',
        'title': 'Raise Seed from a local European investor',
        'revenue': 0,
        'headcount': 3,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'right',
        'displayTable': 'true',
        'personWaypoint': 'animePeopleCompass1',
    },
    {
        'title': 'Build product from Europe, establishing product/market fit',
        'revenue': 0,
        'headcount': 6,
        'revenueUs': 0,
        'headcountUs': 0,
    },
    {
        'revenue': 0.5,
        'headcount': 8,
        'revenueUs': 0,
        'headcountUs': 0,
    },
    {
        'year': 2,
        'title': 'Acquire and service customers from the US remotely',
        'revenue': 2,
        'headcount': 12,
        'revenueUs': 0.2,
        'headcountUs': 0,
        'quoteText': 'Collibra began to sell remotely into the US, sponsoring conferences and sending over team members to attend. They added 10-12 new clients this way, servicing them remotely. ',
    },
    {
        'header': 'Series A',
        'title': 'Raise Series A from transatlantic or US investor',
        'revenue': 6,
        'headcount': 30,
        'revenueUs': 0.6,
        'headcountUs': 0,
        'layout': 'left',
        'displayTable': 'true',
        'isSeriesAData': 'true',
    },
    {
        'year': 3,
        'title': 'Refocus efforts on US versus Europe - both for sales and product roadmap',
        'revenue': 6,
        'headcount': 30,
        'revenueUs': 0.6,
        'headcountUs': 0,
        'layout': 'left',
    },
    {
        'waypoint': 'animeRefocusUs',
    },
    {
        'title': 'Strengthen product management so CEO can step away from day to day involvement',
        'quoteText': 'When we first landed in the US, I was too immersed in day-to-day product decisions to move full-time to NY. So two of my co-founders were there for the first two years before I could join them',
        'quoteAuthor': 'Felix van de Maele',
        'quoteCompany': 'CEO & co-founder, Collibra',
        'personWaypoint': 'animePeopleCompass2',
    },
    {
        'title': 'CEO moves to (or spends more time in) the US',
        'layout': 'right',
        'quoteText': 'Momentum won&rsquo;t come as fast if you&rsquo;re not here. You&rsquo;ve got to show that you&rsquo;re committed, to talent and to partners',
        'quoteAuthor': 'Nicolas Dessaigne',
        'quoteCompany': 'Former CEO & co-founder, Algolia',
        'personWaypoint': 'animePeopleCompass3',
    },
    {
        'title': 'Build a small sales team in US, led by a player/coach',
        'revenue': 10,
        'headcount': 60,
        'revenueUs': 2,
        'headcountUs': 6,
        'layout': 'left',
        'personWaypoint': 'animePeopleCompass4',
    },
    {
        'year': 4,
        'header': 'Series B',
        'title': 'Raise Series B led by US investor',
        'revenue': 15,
        'headcount': 100,
        'revenueUs': 5,
        'headcountUs': 20,
        'layout': 'right',
        'displayTable': 'true',
        'quoteText': 'It is an absolute requirement for Series B to have a VC with a US network. Great investors are very valuable for building quality management teams',
        'quoteAuthor': 'Felix van de Maele,',
        'quoteCompany': 'CEO & co-founder, Collibra',
    },
    {
        'title': 'Scale your go-to-market team in US',
        'revenue': 20,
        'headcount': 150,
        'revenueUs': 8,
        'headcountUs': 35,
        'layout': 'left',
        'personWaypoint': 'animePeopleCompass5',
    },
    {
        'year': 5,
        'title': 'CEO relocates<br/>(if hasn&rsquo;t already)',
        'revenue': 25,
        'headcount': 200,
        'revenueUs': 11,
        'headcountUs': 54,
        'layout': 'right',
        'personWaypoint': 'animePeopleCompass6',
    },
    {
        'personWaypoint': 'animePeopleCompass7',
    },
    {
        'header': 'Series C',
        'title': 'Raise Series C',
        'revenue': 30,
        'headcount': 250,
        'revenueUs': 15,
        'headcountUs': 75,
        'layout': 'left',
        'displayTable': 'true',
        'personWaypoint': 'animePeopleCompass8',
    },
    {
        'year': 6,
        'revenue': 40,
        'headcount': 325,
        'title': 'All non-technical leadership is now in the US, with dedicated commercial leader in Europe',
        'revenueUs': 21,
        'headcountUs': 111,
        'layout': 'right',
        'personWaypoint': 'animePeopleCompass9',
    },
    {
        'revenue': 50,
        'headcount': 400,
        'revenueUs': 28,
        'headcountUs': 152,
    },
    {
        'year': 7,
        'header': 'Series D',
        'title': 'Raise Series D',
        'revenue': 60,
        'headcount': 500,
        'revenueUs': 36,
        'headcountUs': 200,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'Hire CTO/CPO in US',
        'revenue': 80,
        'headcount': 600,
        'revenueUs': 48,
        'headcountUs': 252,
        'layout': 'right',
        'quoteText': 'There are fewer than 20 CTOs in Europe who have run 200+ people engineering teams at hypergrowth',
        'quoteAuthor': 'Dominic Jacquesson',
        'quoteCompany': 'Index Ventures',
        // 'why': 'It\'s hard to find candidates in Europe with experience at this scale',
        'personWaypoint': 'animePeopleCompass10',
    },
    {
        'title': 'Consider a secondary engineering centre in US',
        'layout': 'right',
        'waypoint': 'animePulseOverAustin',
    },
    {
        'year': 8,
        'title': 'Build customer-oriented product team in US',
        'revenue': 100,
        'headcount': 700,
        'revenueUs': 60,
        'headcountUs': 308,
        'layout': 'left',
        'quoteText': 'Product leadership has to be close to customers and to sales. Most of our senior PMs are in the US now [rest of tech team is in Europe]',
        'quoteAuthor': 'Peter Bauer',
        'quoteCompany': 'CEO & co-founder, Mimecast',
    },
    {
        'revenue': 120,
        'headcount': 800,
        'title': 'Raise Series E',
        'revenueUs': 72,
        'headcountUs': 360,
        'header': 'Series E',
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'revenue': 160,
        'headcount': 1000,
        'revenueUs': 96,
        'headcountUs': 480,
    },
    {
        'year': 9,
        'header': 'Listing',
        'title': 'List in the US',
        'revenue': 200,
        'headcount': 1200,
        'revenueUs': 120,
        'headcountUs': 600,
        'layout': 'left',
        'displayTable': 'true',
        'isListingData': 'true',
        'waypoint': 'animeFireworks',
    },
]

import $ from 'jquery'
import Archetypes from './archetypes'
import SentryWrapper from '../../../ui/js/utils/SentryWrapper'

// On doc.ready.
$(function () {
    new SentryWrapper()
    Archetypes.init()
})




export default function attachSocialShareEvents() {
    const socialShareElements = document.querySelectorAll('[data-track-event-category="Social Share Click"]')
    socialShareElements.forEach((element) => {
        element.addEventListener('click', () => {
            if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'social_share', {...element.dataset})
            }
        })
    })
}

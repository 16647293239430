import Vue from 'vue'

let ivFooterCharts = Vue.component('iv-footer-charts', {
    template: '#iv-footer-charts',
    props: [
        'archetypes',
        'myArchetype',
        'journey',
    ],
    data() {
        return {
            dataAtPosition: {},
        }
    },
    delimiters: ['[[', ']]'],

    methods: {
        calcPercent(x, y) {
            if (y === 0) {
                return 0
            } else {
                return x / y * 100
            }
        },
    },

    computed: {
        archetype() {
            return this.$props.archetypes[this.$props.myArchetype]
        },

        archetypeScrollData() {
            return this.archetype.scrollData
        },

        progress() {
            return this.$props.journey.progress
        },

        journeyYPos() {
            return this.$props.journey.yPos
        },

        listingData() {
            for (let section of this.archetype.sections) {
                if (section.hasOwnProperty('isListingData')) {
                    return section
                }
            }
        },

        sectionDataLow() {
            let sectionDataLow = this.archetypeScrollData[0] || {offsetTop: 0, offsetBottom: 0, sectionId: 0, revenue: 0, headcount: 0, revenueUs: 0, headcountUs: 0}
            for (let s = 0; s < this.archetypeScrollData.length; s++) {
                if (this.journeyYPos > this.archetypeScrollData[s].offsetTop) {
                    sectionDataLow = this.archetypeScrollData[s]
                }
            }
            return sectionDataLow
        },

        sectionDataHigh() {
            let sectionDataHigh = this.sectionDataLow
            // Loop through all but the last one
            for (let s = 0; s < this.archetypeScrollData.length - 1; s++) {
                if (this.journeyYPos > this.archetypeScrollData[s].offsetTop) {
                    sectionDataHigh = this.archetypeScrollData[s + 1]
                }
            }
            return sectionDataHigh
        },

        sectionDataTween() {
            let low = this.sectionDataLow
            let high = this.sectionDataHigh
            let yDiff = this.journeyYPos - low.offsetTop
            yDiff = yDiff < 0 ? 0 : yDiff
            let maxYDiff = high.offsetTop - low.offsetTop
            maxYDiff = maxYDiff < 0 ? 0 : maxYDiff
            let diffMultiplier = 0
            if (maxYDiff > 0) {
                diffMultiplier = yDiff / maxYDiff
            }

            return {
                low: low,
                high: high,
                revenue: low.revenue + (diffMultiplier * (high.revenue - low.revenue)),
                revenueUs: low.revenueUs + (diffMultiplier * (high.revenueUs - low.revenueUs)),
                headcount: low.headcount + (diffMultiplier * (high.headcount - low.headcount)),
                headcountUs: low.headcountUs + (diffMultiplier * (high.headcountUs - low.headcountUs)),
                lowOffsetTop: low.offsetTop,
                highOffsetTop: high.offsetTop,
                journeyYPos: this.journeyYPos,
                yDiff: yDiff,
                maxYDiff: maxYDiff,
                diffMultiplier: diffMultiplier,
            }
        },

        sectionRevenue() {
            let total = this.sectionDataTween.revenue
            let us = this.sectionDataTween.revenueUs
            let eu = total - us

            return {
                total: total,
                us: us,
                eu: eu,
                usPercent: this.calcPercent(us, total),
                usBarPercent: this.calcPercent(us, this.listingData.revenue),
                euPercent: this.calcPercent(eu, total),
                euBarPercent: this.calcPercent(eu, this.listingData.revenue),
            }
        },

        sectionHeadcount() {
            let total = this.sectionDataTween.headcount
            let us = this.sectionDataTween.headcountUs
            let eu = total - us

            return {
                total: Math.round(total),
                us: Math.round(us),
                eu: Math.round(total) - Math.round(us),
                usPercent: Math.round(this.calcPercent(us, total)),
                usBarPercent: this.calcPercent(us, this.listingData.headcount),
                euPercent: Math.round(this.calcPercent(eu, total)),
                euBarPercent: this.calcPercent(eu, this.listingData.headcount),
            }
        },

        section() {
            return {
                revenue: {
                    title: 'Revenue',
                    us: {
                        label: `${this.sectionRevenue.usPercent.toFixed(0)}%`,
                        barStyle: `width:${this.sectionRevenue.usBarPercent.toFixed(4)}%;`,
                        labelReversed: this.sectionRevenue.usBarPercent > 75,
                    },
                    eu: {
                        label: `${this.sectionRevenue.euPercent.toFixed(0)}%`,
                        barStyle: `width:${this.sectionRevenue.euBarPercent.toFixed(4)}%;`,
                        labelReversed: this.sectionRevenue.euBarPercent > 75,
                    },
                    total: this.sectionRevenue.total < 10 ? `$${this.sectionRevenue.total.toFixed(1)}m` : `$${Math.round(this.sectionRevenue.total)}m`,
                },
                headcount: {
                    title: 'Headcount',
                    us: {
                        label: this.sectionHeadcount.us,
                        barStyle: `width:${this.sectionHeadcount.usBarPercent.toFixed(4)}%;`,
                        labelReversed: this.sectionHeadcount.usBarPercent > 75,
                    },
                    eu: {
                        label: this.sectionHeadcount.eu,
                        barStyle: `width:${this.sectionHeadcount.euBarPercent.toFixed(4)}%;`,
                        labelReversed: this.sectionHeadcount.euBarPercent > 75,
                    },
                    total: this.sectionHeadcount.total,
                },
            }
        },
    },
})

export {ivFooterCharts}
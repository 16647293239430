export default {
    1: {
        question:
            'What % of your TAM (Total Addressable Market) is&nbsp;the&nbsp;US?',
        options: {
            '15-30': '15&ndash;30%',
            '30-50': '30&ndash;50%',
            '50-100': '50%+',
        },
        defaultAnswer: '50-100',
        answer: '',
        tooltip: 'For guidance on calculating your TAM, read <a href="/destination-usa/how-important-is-the-us-market" target="_blank">here</a>',
        tooltipOpen: false,
    },
    2: {
        question: 'What is your GTM<br/>(go&#8209;to&#8209;market) model?',
        options: {
            sales: 'Sales-driven',
            marketing: 'Marketing-driven',
        },
        defaultAnswer: 'sales',
        answer: '',
        tooltip: 'Marketing-driven includes self-serve or app store',
        tooltipOpen: false,
    },
    3: {
        question: 'Do you need a lot of local&nbsp;ops?',
        options: {
            intensive: 'Intensive',
            minimal: 'Minimal',
        },
        defaultAnswer: 'minimal',
        answer: '',
        tooltip: 'Eg for fulfilment or implementation',
        tooltipOpen: false,
    },
    4: {
        question: 'Do you face major US regulatory&nbsp;barriers?',
        options: {
            yes: 'Yes',
            no: 'No',
        },
        defaultAnswer: 'no',
        answer: '',
        tooltip: 'For more guidance, read <a href="/destination-usa/localisation-and-regulation" target="_blank">here</a>',
        tooltipOpen: false,
    },
    5: {
        question:
            'Will you require significant product or GTM localisation for&nbsp;the&nbsp;US?',
        options: {
            yes: 'Yes',
            no: 'No',
        },
        defaultAnswer: 'no',
        answer: '',
        tooltip: 'For more guidance, read <a href="/destination-usa/localisation-and-regulation" target="_blank">here</a>',
        tooltipOpen: false,
    },
    6: {
        question: 'Do you face a strong direct US competitor?',
        options: {
            yes: 'Yes',
            no: 'No',
        },
        defaultAnswer: 'no',
        answer: '',
        tooltip: 'For more guidance, read <a href="/destination-usa/competitive-environment" target="_blank">here</a>',
        tooltipOpen: false,
    },
}

import Vue from 'vue'
import VueWaypoint from 'vue-waypoint'

Vue.use(VueWaypoint)

let ivJourneySection = Vue.component('iv-journey-section', {
    props: [
        'section',
        'anime',
    ],
    delimiters: ['[[', ']]'],
    data: function () {
        return {
            foreignHireTime: 2000,
        }
    },

    methods: {
        getAnimeCallback(name) {
            if (typeof this[name] === 'function') {
                return this[name]
            }
        },

        animeRefocusUs(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                this.$props.anime.refocusUs.play()
            }
        },

        animePulseOverSF(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                this.$props.anime.pulseOver.sf.show.play()
            }
        },

        animePulseOverAustin(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                this.$props.anime.pulseOver.austin.show.play()
            }
        },

        animePulseOverUS(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                this.$props.anime.pulseOver.us.show.play()
            }
        },

        animePulseOverUk(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                this.$props.anime.pulseOver.uk.show.play()
            }
        },

        animePulseOverFranceAndUk(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                this.$props.anime.pulseOver.france.show.play()
                setTimeout(() => {
                    this.$props.anime.pulseOver.uk.show.play()
                }, 500)
            }
        },

        animePulseOverFranceAndSpain(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                this.$props.anime.pulseOver.france.show.play()
                setTimeout(() => {
                    this.$props.anime.pulseOver.spain.show.play()
                }, 500)
            }
        },

        animeTravelLoopFromCeo(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                // Play the small loop if there's not enough space for the standard loop.
                if (this.$props.anime.people.founderCeo.x < 500) {
                    this.$props.anime.travelLoop[2].show.restart()
                } else {
                    this.$props.anime.travelLoop[1].show.restart()
                }
            }
        },

        animeTravelLoopFromCeoX2(waypoint) {
            if (this.$parent.animeGoingIn(waypoint)) {
                // Play the small loop if there's not enough space for the standard loop.
                if (this.$props.anime.people.founderCeo.x < 500) {
                    this.$props.anime.travelLoop[2].showX2.restart()
                } else {
                    this.$props.anime.travelLoop[1].showX2.restart()
                }
            }
        },

        animeFireworks(waypoint) {
            if (this.$parent.animeShow(waypoint)) {
                this.$props.anime.fireworks.play()
            }
        },

        // Telescope animations
        animePeopleTelescope1(waypoint) {
            this.$parent.controlPeople(1, waypoint)
            this.$parent.showHideLeadershipTitles(waypoint)
        },

        animePeopleTelescope2(waypoint) {
            this.$parent.controlPeople(2, waypoint)
        },

        animePeopleTelescope3(waypoint) {
            this.$parent.controlPeople(3, waypoint)
        },

        animePeopleTelescope4(waypoint) {
            this.$parent.controlPeople(4, waypoint)
        },

        animePeopleTelescope5(waypoint) {
            this.$parent.controlPeople(5, waypoint)
        },

        // Foreign Hire
        animePeopleTelescope67(waypoint) {
            let positionId = 6
            let t1, t2, t4
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                this.$parent.setCurrentPosition(positionId)
                this.$parent.hidePeopleExcept(positionId)

                t1 = setTimeout(() => {
                    positionId = 7
                    clearTimeout(t4)
                    this.$parent.setCurrentPosition(positionId)
                    this.$parent.movePeople(positionId)

                    this.$parent.moveForeignHire(1, 'cmo')
                    this.$props.anime.foreignHire[1].show.restart()

                    this.$parent.moveForeignHire(2, 'vpGrowth')
                    this.$props.anime.foreignHire[2].show.restart()

                    t2 = setTimeout(() => {
                        this.$parent.showPeople(positionId)
                    }, this.foreignHireTime)
                }, 1000)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 6
                clearTimeout(t1)
                clearTimeout(t2)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.hidePeopleExcept(positionId)

                t4 = setTimeout(() => {
                    positionId = 5
                    this.$parent.setCurrentPosition(positionId)
                    this.$parent.movePeople(positionId)
                    this.$parent.showPeople(positionId)
                    this.$parent.hidePeopleExcept(positionId)
                }, 500)
            }
        },

        animePeopleTelescope8(waypoint) {
            this.$parent.controlPeople(8, waypoint)
        },

        animePeopleTelescope9(waypoint) {
            let positionId = 9
            let t1, t2, t3
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                console.log('animePeopleTelescope9 show')
                this.$parent.setCurrentPosition(positionId)

                this.$parent.movePeople(positionId)

                // Move the one we are replacing
                this.$parent.movePersonToPerson('founderCto', 'cto')

                this.$parent.moveForeignHire(1, 'cto')
                this.$props.anime.foreignHire[1].show.restart()

                t1 = setTimeout(() => {
                    this.$parent.hidePeopleExcept(positionId)
                    // We have a hidden cpo so we don't show everyone
                    this.$parent.showPerson('cto')

                    t2 = setTimeout(() => {
                        this.$parent.moveForeignHire(2, 'cpo')
                        this.$props.anime.foreignHire[2].show.restart()

                        t3 = setTimeout(() => {
                            this.$parent.showPeople(positionId)
                        }, this.foreignHireTime)
                    }, 1000)
                }, this.foreignHireTime)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 8
                clearTimeout(t1)
                clearTimeout(t2)
                clearTimeout(t3)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.showPeople(positionId)
                this.$parent.hidePeopleExcept(positionId)
            }
        },


        // Anchor animations
        animePeopleAnchor1(waypoint) {
            this.$parent.controlPeople(1, waypoint)
            this.$parent.showHideLeadershipTitles(waypoint)
        },

        animePeopleAnchor2(waypoint) {
            this.$parent.controlPeople(2, waypoint)
        },

        animePeopleAnchor3(waypoint) {
            this.$parent.controlPeople(3, waypoint)
        },

        animePeopleAnchor4(waypoint) {
            this.$parent.controlPeople(4, waypoint)
        },

        // Foreign Hire
        animePeopleAnchor5(waypoint) {
            let positionId = 5
            let t1
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.hidePeopleExcept(positionId)

                this.$parent.moveForeignHire(5, 'landingTeam')
                this.$props.anime.foreignHire[5].show.restart()

                t1 = setTimeout(() => {
                    this.$parent.showPeople(positionId)
                }, this.foreignHireTime)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 4
                clearTimeout(t1)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.showPeople(positionId)
                this.$parent.hidePeopleExcept(positionId)
            }
        },

        // Foreign Hire
        animePeopleAnchor6(waypoint) {
            let positionId = 6
            let t1
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.hidePeopleExcept(positionId)

                this.$parent.moveForeignHire(6, 'cmo')
                this.$props.anime.foreignHire[6].show.restart()

                t1 = setTimeout(() => {
                    this.$parent.showPeople(positionId)
                }, this.foreignHireTime)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 5
                clearTimeout(t1)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.showPeople(positionId)
                this.$parent.hidePeopleExcept(positionId)
            }
        },

        animePeopleAnchor7(waypoint) {
            this.$parent.controlPeople(7, waypoint)
        },

        // Todo anchor pendulum and telescope have the same final step so should refactor
        animePeopleAnchor8(waypoint) {
            let positionId = 8
            let t1, t2, t3
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                this.$parent.setCurrentPosition(positionId)

                this.$parent.movePeople(positionId)
                // Manually move founderCto even though they are about to vanish
                this.$parent.movePersonToPerson('founderCto', 'cto')

                this.$parent.moveForeignHire(1, 'cto')
                this.$props.anime.foreignHire[1].show.restart()

                t1 = setTimeout(() => {
                    this.$parent.hidePeopleExcept(positionId)
                    // We have a hidden cpo so we don't show everyone
                    this.$parent.showPerson('cto')

                    t2 = setTimeout(() => {
                        this.$parent.moveForeignHire(2, 'cpo')
                        this.$props.anime.foreignHire[2].show.restart()

                        t3 = setTimeout(() => {
                            this.$parent.showPeople(positionId)
                        }, this.foreignHireTime)
                    }, 1000)
                }, this.foreignHireTime)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 7
                clearTimeout(t1)
                clearTimeout(t2)
                clearTimeout(t3)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.showPeople(positionId)
                this.$parent.hidePeopleExcept(positionId)
            }
        },

        // Compass animations
        animePeopleCompass1(waypoint) {
            this.$parent.controlPeople(1, waypoint)
            this.$parent.showHideLeadershipTitles(waypoint)
        },

        animePeopleCompass2(waypoint) {
            this.$parent.controlPeople(2, waypoint)
        },

        // Split Founder CEOs
        animePeopleCompass3(waypoint) {
            let positionId = 3
            let t1, t2, t3, t4
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                clearTimeout(t4)

                this.$parent.setCurrentPosition(positionId)

                // Highlight founderCEO
                this.anime.people.founderCeo.highlight.play()

                // Move founderCeoSplit to founderCeo
                this.$parent.movePersonToPerson('founderCeoSplit', 'founderCeo')
                this.anime.people.founderCeoSplit.moveSpeed = 'slow'

                // Move founderCeoSplit2 to founderCeo
                this.$parent.movePersonToPerson('founderCeoSplit2', 'founderCeo')

                t1 = setTimeout(() => {
                    // We don't show everyone because we have a hidden vpSales in there
                    this.$parent.showPeople(positionId)
                    this.$parent.hidePeopleExcept(positionId)

                    t2 = setTimeout(() => {
                        // Move founderCeoSplit to us
                        this.$parent.movePeople(positionId)
                        // Animate the trail
                        this.anime.people.founderCeoSplit.trail.restart()

                        t3 = setTimeout(() => {
                            this.anime.people.founderCeoSplit.moveSpeed = 'default'
                        }, 1500)
                    }, 100)
                }, 1000)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 2
                clearTimeout(t1)
                clearTimeout(t2)
                clearTimeout(t3)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                // Return the US split to EU
                this.$parent.movePersonToPerson('founderCeoSplit', 'founderCeoSplit2')

                t4 = setTimeout(() => {
                    this.$parent.showPeople(positionId)
                    this.$parent.hidePeopleExcept(positionId)
                }, 500)
            }
        },

        animePeopleCompass4(waypoint) {
            this.$parent.controlPeople(4, waypoint)
        },

        animePeopleCompass5(waypoint) {
            this.$parent.controlPeople(5, waypoint)
        },

        // Rejoin Founder CEO Splits
        animePeopleCompass6(waypoint) {
            let positionId = 6
            let t1, t2, t4
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                clearTimeout(t4)
                this.$parent.setCurrentPosition(positionId)

                // Move founderCeo to founderCeoSplit
                this.$parent.movePersonToPerson('founderCeo', 'founderCeoSplit')

                // Move founderCeoSplit2 to founderCeoSplit
                this.anime.people.founderCeoSplit2.moveSpeed = 'slow'
                this.$parent.movePersonToPerson('founderCeoSplit2', 'founderCeoSplit')

                // animate the tail
                this.anime.people.founderCeoSplit2.trail.restart()

                t1 = setTimeout(() => {
                    this.$parent.showPeople(positionId)

                    this.anime.people.founderCeoSplit2.moveSpeed = 'default'

                    t2 = setTimeout(() => {
                        this.$parent.hidePeopleExcept(positionId)
                        // Move anyone else
                        this.$parent.movePeople(positionId)
                    }, 500)
                }, 1500)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 5
                clearTimeout(t1)
                clearTimeout(t2)
                this.$parent.setCurrentPosition(positionId)
                // Start the EU split in the US
                this.$parent.movePersonToPerson('founderCeoSplit2', 'founderCeoSplit')

                this.$parent.showPeople(positionId)
                this.$parent.hidePeopleExcept(positionId)

                t4 = setTimeout(() => {
                    this.$parent.movePeople(positionId)
                }, 500)
            }
        },

        animePeopleCompass7(waypoint) {
            this.$parent.controlPeople(7, waypoint)
        },

        animePeopleCompass8(waypoint) {
            this.$parent.controlPeople(8, waypoint)
        },

        animePeopleCompass9(waypoint) {
            this.$parent.controlPeople(9, waypoint)
        },

        animePeopleCompass10(waypoint) {
            this.$parent.controlPeople(10, waypoint)
        },


        animePeoplePendulum1(waypoint) {
            this.$parent.controlPeople(1, waypoint)
            this.$parent.showHideLeadershipTitles(waypoint)
        },

        animePeoplePendulum2(waypoint) {
            this.$parent.controlPeople(2, waypoint)
        },

        // Foreign Hire
        animePeoplePendulum3(waypoint) {
            let positionId = 3
            let t1
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.hidePeopleExcept(positionId)

                this.$parent.moveForeignHire(5, 'landingTeam')
                this.$props.anime.foreignHire[5].show.restart()

                t1 = setTimeout(() => {
                    this.$parent.showPeople(positionId)
                }, this.foreignHireTime)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 2
                clearTimeout(t1)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.showPeople(positionId)
                this.$parent.hidePeopleExcept(positionId)
            }
        },

        animePeoplePendulum4(waypoint) {
            this.$parent.controlPeople(4, waypoint)
        },

        // Founder CEO split
        animePeoplePendulum5(waypoint) {
            let positionId = 5
            let t1, t2, t3, t4
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                clearTimeout(t4)

                this.$parent.setCurrentPosition(positionId)

                // Highlight founderCEO
                this.anime.people.founderCeo.highlight.play()

                // Move founderCeoSplit to founderCeo
                this.$parent.movePersonToPerson('founderCeoSplit', 'founderCeo')
                this.anime.people.founderCeoSplit.moveSpeed = 'slow'

                // Move founderCeoSplit2 to founderCeo
                this.$parent.movePersonToPerson('founderCeoSplit2', 'founderCeo')

                t1 = setTimeout(() => {
                    this.$parent.showPeople(positionId)
                    this.$parent.hidePeopleExcept(positionId)

                    t2 = setTimeout(() => {
                        // Move founderCeoSplit to us
                        this.$parent.movePeople(positionId)
                        // Animate the trail
                        this.anime.people.founderCeoSplit.trail.restart()

                        t3 = setTimeout(() => {
                            this.anime.people.founderCeoSplit.moveSpeed = 'default'
                        }, 1500)
                    }, 100)
                }, 1000)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 6
                clearTimeout(t1)
                clearTimeout(t2)
                clearTimeout(t3)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                // Return the US split to EU
                this.$parent.movePersonToPerson('founderCeoSplit', 'founderCeoSplit2')
                t4 = setTimeout(() => {
                    this.$parent.showPeople(positionId)
                    this.$parent.hidePeopleExcept(positionId)
                }, 500)
            }
        },

        animePeoplePendulum6(waypoint) {
            this.$parent.controlPeople(6, waypoint)
        },

        animePeoplePendulum7(waypoint) {
            this.$parent.controlPeople(7, waypoint)
        },

        animePeoplePendulum8(waypoint) {
            this.$parent.controlPeople(8, waypoint)
        },

        animePeoplePendulum9(waypoint) {
            let positionId = 9
            let t1, t2, t3
            this.$parent.debugWaypoint(waypoint, positionId)

            if (this.$parent.animeShow(waypoint, positionId)) {
                this.$parent.setCurrentPosition(positionId)

                this.$parent.movePeople(positionId)

                // Move the one we are replacing
                this.$parent.movePersonToPerson('founderCto', 'cto')

                this.$parent.moveForeignHire(1, 'cto')
                this.$props.anime.foreignHire[1].show.restart()

                t1 = setTimeout(() => {
                    this.$parent.hidePeopleExcept(positionId)
                    // We have a hidden cpo so we don't show everyone
                    this.$parent.showPerson('cto')

                    t2 = setTimeout(() => {
                        this.$parent.moveForeignHire(2, 'cpo')
                        this.$props.anime.foreignHire[2].show.restart()

                        t3 = setTimeout(() => {
                            this.$parent.showPeople(positionId)
                        }, this.foreignHireTime)
                    }, 1000)
                }, this.foreignHireTime)
            }
            if (this.$parent.animeHide(waypoint)) {
                positionId = 8
                clearTimeout(t1)
                clearTimeout(t2)
                clearTimeout(t3)
                this.$parent.setCurrentPosition(positionId)
                this.$parent.movePeople(positionId)
                this.$parent.showPeople(positionId)
                this.$parent.hidePeopleExcept(positionId)
            }
        },
    },

    computed: {
        sectionClasses() {
            return this.$props.section.addSectionClasses ? this.$props.section.addSectionClasses : ''
        },

        layoutClass() {
            return this.$props.section.layout ? `layout-${this.$props.section.layout}` : 'layout-left'
        },

        yearOnlyOrEmptySection() {
            // If the section ONLY has a year or no content in it...
            if (!this.$props.section.title
                && !this.$props.section.waypoint
                && !this.$props.section.personWaypoint
                && !this.$props.section.header
                && !this.$props.section.title
                && !this.$props.section.why
                && !this.$props.section.displayTable
                && !this.$props.section.quoteText
                && !this.$props.section.quoteAuthor
                && !this.$props.section.quoteCompany) {
                if (this.$props.section.year) {
                    return 'is-year-only-section'
                } else {
                    return 'is-empty-section'
                }
            }
            return ''
        },

        revenue() {
            return this.$props.section.revenue
        },

        headcount() {
            return this.$props.section.headcount
        },

        revenueUs() {
            return this.$props.section.revenueUs
        },

        headcountUs() {
            return this.$props.section.headcountUs
        },

        revenueUsPercent() {
            if (this.revenue === 0) {
                return 0
            } else {
                return Math.round(this.revenueUs / this.revenue * 100)
            }
        },

        headcountUsPercent() {
            if (this.headcount === 0) {
                return 0
            } else {
                return Math.round(this.headcountUs / this.headcount * 100)
            }
        },

        yearLabel() {
            if ('year' in this.$props.section) {
                // Exclude .5 years
                if (parseInt(this.$props.section.year) === parseFloat(this.$props.section.year)) {
                    return `<strong>Year ${this.$props.section.year}</strong> of ${this.$parent.yearMax}`
                }
            }
            return false
        },

    },

    template: '#iv-journey-section',
})

export {ivJourneySection}
export default [
    {
        'year': 1,
        'header': 'Seed',
        'title': 'Raise Seed from a local European investor',
        'revenue': 0,
        'headcount': 3,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'right',
        'displayTable': 'true',
        'personWaypoint': 'animePeopleTelescope1',
    },
    {
        'title': 'Build product from Europe, establishing product/market fit',
        'revenue': 0,
        'headcount': 6,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'right',
    },
    {
        'revenue': 0.5,
        'headcount': 8,
        'revenueUs': 0.2,
        'headcountUs': 0,
    },
    {
        'year': 2,
        'header': 'Series A',
        'title': 'Raise Series A from transatlantic or European investor',
        'revenue': 2,
        'headcount': 12,
        'revenueUs': 1,
        'headcountUs': 0,
        'layout': 'right',
        'displayTable': 'true',
        'isSeriesAData': 'true',
    },
    {
        'title': 'Acquire global customers, but localise for US audience',
        'revenue': 2,
        'headcount': 12,
        'revenueUs': 1,
        'headcountUs': 0,
        'layout': 'left',
    },
    {
        'year': 3,
        'title': 'Focus on growth metrics across the whole funnel, optimising in particular for US users',
        'revenue': 6,
        'headcount': 30,
        'revenueUs': 3,
        'headcountUs': 0,
        'layout': 'left',
        'quoteText': 'Find a niche in the US, and grow from there. Try to select the smallest segment you can. Not the largest. Don\'t be tempted to market to everyone',
        'quoteAuthor': 'Gil Sadis',
        'quoteCompany': 'VP Product, Lemonade',
    },
    {
        'title': 'Make frequent visits to the US to cultivate relationships with strategic distribution partners',
        'layout': 'left',
        'waypoint': 'animeTravelLoopFromCeo',
    },
    {
        'waypoint': 'animePulseOverSF',
    },
    {
        'title': 'Establish small business development team (probably in the Bay Area)',
        'revenue': 10,
        'headcount': 60,
        'revenueUs': 6,
        'headcountUs': 5,
        'layout': 'left',
        'quoteText': 'The only reason for gaming businesses to have local offices is for distribution partnerships. This doesn&rsquo;t need large teams, and principally means the US',
        'quoteAuthor': 'Stephane Kurgan',
        'quoteCompany': 'former COO, King',
        // 'why': 'You\'ll probably have major channel or product partners in US',
        'personWaypoint': 'animePeopleTelescope2',
    },
    {
        'year': 4,
        'header': 'Series B',
        'title': 'Raise Series B led by transatlantic investor',
        'revenue': 15,
        'headcount': 100,
        'revenueUs': 8,
        'headcountUs': 10,
        'layout': 'right',
        'displayTable': 'true',
    },
    {
        'title': 'Hire business development lead in US',
        'layout': 'left',
        'personWaypoint': 'animePeopleTelescope3',
    },
    {
        'title': 'Build US support team in secondary hub or remote. Continue building leadership in Europe',
        'revenue': 20,
        'headcount': 150,
        'revenueUs': 11,
        'headcountUs': 15,
        'layout': 'right',
        'waypoint': 'animePulseOverAustin',
        'personWaypoint': 'animePeopleTelescope4',
    },
    {
        'year': 5,
        'title': 'Explore ways of moving up the value chain with higher ACVs and larger organisations, using inside sales (B2B only)',
        'revenue': 25,
        'headcount': 200,
        'revenueUs': 14,
        'headcountUs': 22,
        'layout': 'left',
        'personWaypoint': 'animePeopleTelescope5',
    },
    {
        'header': 'Series C',
        'title': 'Raise Series C led by US investor',
        'revenue': 30,
        'headcount': 250,
        // Lowered this from 17 to 16.5 to make it 55%
        'revenueUs': 16.5,
        'headcountUs': 30,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'Scale-up inside sales in US secondary hub (B2B only)',
        'layout': 'left',
        'waypoint': 'animePulseOverAustin',
    },
    {
        'year': 6,
        'title': 'Leadership team mostly in Europe, but likely to include several executives relocating from US',
        'revenue': 40,
        'headcount': 325,
        'revenueUs': 22,
        'headcountUs': 40,
        'layout': 'left',
        'quoteText': 'Most of King&rsquo;s leadership team was hired from within Europe. The European gaming sector has a strong talent pool, which made this possible. However, as they prepared for an IPO in New York, they found an experienced CFO in the US, who relocated to London.',
        'personWaypoint': 'animePeopleTelescope67',
        'addSectionClasses': 'no-quote-switch',
    },
    {
        'revenue': 50,
        'headcount': 400,
        'revenueUs': 28,
        'headcountUs': 50,
    },
    {
        'year': 7,
        'header': 'Series D',
        'title': 'Raise Series D',
        'revenue': 60,
        'headcount': 500,
        'revenueUs': 33,
        'headcountUs': 60,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'Set up enterprise sales function, if you continue to move up the value chain (B2B only)',
        'revenue': 80,
        'headcount': 600,
        'revenueUs': 44,
        'headcountUs': 80,
        'layout': 'left',
        'personWaypoint': 'animePeopleTelescope8',
    },
    {
        'title': 'If this happens, you&rsquo;re likely to morph into the Compass archetype&hellip;',
        'layout': 'right',
        'quoteText': 'The most successful self-serve B2B startups transition to serving enterprise clients - Zendesk, Dropbox, Slack&hellip;',
        'quoteAuthor': 'Sofia Dolfe',
        'quoteCompany': 'Index Ventures',
    },
    {
        'year': 8,
        'title': 'If the right CTO/CPO candidates can no longer be found in Europe, relocate execs from US',
        'revenue': 100,
        'headcount': 700,
        'revenueUs': 55,
        'headcountUs': 100,
        'layout': 'left',
        // 'why': 'It\'s hard to find candidates in Europe with experience at this scale',
        'quoteText': 'In Barcelona we do not have that many SaaS experienced professionals who have ‘been there and done that&rsquo;. Therefore it is necessary to relocate them so that we can learn and scale our business faster.',
        'quoteAuthor': 'Joaquim Lechà',
        'quoteCompany': 'CEO, Typeform',
        'personWaypoint': 'animePeopleTelescope9',

    },
    {
        'header': 'Series E',
        'title': 'Raise Series E',
        'revenue': 120,
        'headcount': 800,
        'revenueUs': 66,
        'headcountUs': 120,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'revenue': 160,
        'headcount': 1000,
        'revenueUs': 88,
        'headcountUs': 150,
    },
    {
        'year': 9,
        'title': 'List probably in the US',
        'revenue': 200,
        'headcount': 1200,
        'revenueUs': 110,
        'headcountUs': 180,
        'header': 'Listing',
        'layout': 'left',
        'displayTable': 'true',
        'isListingData': 'true',
        'waypoint': 'animeFireworks',
    },
]
export default [
    {
        'year': 1,
        'header': 'Seed',
        'title': 'Raise Seed from a local European investor',
        'revenue': 0,
        'headcount': 3,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'right',
        'displayTable': 'true',
        'personWaypoint': 'animePeoplePendulum1',
    },
    {
        'title': 'Build product from Europe, establishing product/market fit',
        'revenue': 0,
        'headcount': 6,
        'revenueUs': 0,
        'headcountUs': 0,
        'quoteText': 'In the early days, you need good product/market fit - and that needs a limited market. In our case, this was a geographic limit [Sweden]. For lots of industries, going global on day one is too capital intensive - you can&rsquo;t address all use-cases',
        'quoteAuthor': 'Daniel Ek',
        'quoteCompany': 'CEO & founder, Spotify',
    },
    {
        'revenue': 0.5,
        'headcount': 8,
        'revenueUs': 0,
        'headcountUs': 0,
    },
    {
        'year': 2,
        'header': 'Series A',
        'title': 'Raise Series A from European investor with strong US network',
        'revenue': 2,
        'headcount': 12,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'right',
        'displayTable': 'true',
        'isSeriesAData': 'true',
    },
    {
        'title': 'Focus on European customers, but English as primary language, for product and internally',
        'revenue': 2,
        'headcount': 12,
        'revenueUs': 0,
        'headcountUs': 0,
        'quoteText': 'Tackle the big European markets first, before the US (UK, Fr, Ger)&hellip; this will enable you to see nuances and learnings, and be able to raise the capital required, to go after the US. You will see 10x the competition in the States, and you have to be ready for that',
        'quoteAuthor': 'Daniel Ek',
        'quoteCompany': 'CEO & founder, Spotify',
    },
    {
        'year': 3,
        'title': 'Launch in additional European countries&hellip;',
        'revenue': 6,
        'headcount': 30,
        'revenueUs': 0,
        'headcountUs': 0,
        'quoteText': 'There&rsquo;s a benefit to growing in concentric circles. If we hadn&rsquo;t expanded via London, and had come straight to the US, we&rsquo;d be dead now',
        'quoteAuthor': 'Daniel Ek',
        'quoteCompany': 'CEO & founder, Spotify',
        'waypoint': 'animePulseOverFranceAndUk',
    },
    {
        'title': 'Hire commercial leaders in Europe',
        'revenue': 10,
        'headcount': 60,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'left',
        'personWaypoint': 'animePeoplePendulum2',
    },
    {
        'title': '&hellip; But research US market, with frequent visits. Create launch plan',
        'layout': 'left',
        'waypoint': 'animeTravelLoopFromCeoX2',
    },
    {
        'year': 4,
        'header': 'Series B',
        'title': 'Raise large Series B led by transatlantic investor',
        'revenue': 15,
        'headcount': 100,
        'revenueUs': 0,
        'headcountUs': 5,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'Send a landing team to launch the US, supporting them on product',
        'quoteText': 'At this point, we faced a decision; should we stay concentrated in Europe, or conquer the world - ie the US?',
        'quoteAuthor': 'JB Rudelle',
        'quoteCompany': 'Chairman & former CEO, Criteo',
        // 'why': 'You will need early US localisation to succeed',
        'personWaypoint': 'animePeoplePendulum3',
    },
    {
        'title': 'Strengthen product and commercial leadership in Europe, to free up time for CEO',
        'revenue': 20,
        'headcount': 150,
        'revenueUs': 1,
        'headcountUs': 9,
        'quoteText': 'I only moved to the US once we had strong commercial leaders who I could leave in charge of our growing European business',
        'quoteAuthor': 'JB Rudelle',
        'quoteCompany': 'Chairman & former CEO, Criteo',
        'personWaypoint': 'animePeoplePendulum4',
    },
    {
        'year': 5,
        'title': 'Founder spends extensive time in the US to oversee the ramp-up',
        'revenue': 25,
        'headcount': 200,
        'revenueUs': 3,
        'headcountUs': 16,
        'quoteText': 'I believe success for Spotify comes down to my spending time in the US',
        'quoteAuthor': 'Daniel Ek',
        'quoteCompany': 'CEO & founder, Spotify',
        'personWaypoint': 'animePeoplePendulum5',
    },
    {
        'header': 'Series C',
        'title': 'Raise Series C led by US investor',
        'revenue': 30,
        'headcount': 250,
        'revenueUs': 4,
        'headcountUs': 25,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'year': 6,
        'title': 'Founder potentially relocates to the US as traction proven, else splits time. Hire a dedicated US leader',
        'revenue': 40,
        'headcount': 325,
        'revenueUs': 6,
        'headcountUs': 39,
        'personWaypoint': 'animePeoplePendulum6',
    },
    {
        'title': 'Hire leadership, and build functions, based on where you find the best talent (US or Europe)',
        'revenue': 50,
        'headcount': 400,
        'revenueUs': 9,
        'headcountUs': 52,
        'quoteText': 'If you truly believe your company is going to be big, don&rsquo;t accrue organisational debt; adapt to distributed leadership early, definitely once you&rsquo;re post-Series B',
        'quoteAuthor': 'Daniel Ek',
        'quoteCompany': 'CEO & founder, Spotify',
        'personWaypoint': 'animePeoplePendulum7',
        'addSectionClasses': 'no-quote-switch',
    },
    {
        'year': 7,
        'header': 'Series D',
        'title': 'Raise Series D',
        'revenue': 60,
        'headcount': 500,
        'revenueUs': 12,
        'headcountUs': 75,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'US probably single largest market by now',
        'revenue': 80,
        'headcount': 600,
        'revenueUs': 19,
        'headcountUs': 96,
        'personWaypoint': 'animePeoplePendulum8',
    },
    {
        'title': 'If the right CTO/CPO candidates can no longer be found in Europe, relocate execs from US',
        // 'why': 'It&rsquo;s hard to find candidates in Europe with experience at this scale',
        'quoteText': 'There are fewer than 20 CTOs in Europe who have run 200+ people engineering teams at hypergrowth',
        'quoteAuthor': 'Dominic Jacquesson',
        'quoteCompany': 'Index Ventures',
        'personWaypoint': 'animePeoplePendulum9',
    },
    {
        'year': 8,
        'title': 'Leadership team now distributed across Europe and US',
        'revenue': 100,
        'headcount': 700,
        'revenueUs': 26,
        'headcountUs': 126,
        'quoteText': 'I was focused on getting the best talent, so I accepted the trade-offs in having a distributed leadership team: management tension, lots of travel, and video conferencing',
        'quoteAuthor': 'JB Rudelle',
        'quoteCompany': 'Chairman & former CEO, Criteo',
        'addSectionClasses': 'no-quote-switch',
    },
    {
        'header': 'Series E',
        'title': 'Raise Series E',
        'revenue': 120,
        'headcount': 800,
        'revenueUs': 36,
        'headcountUs': 160,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'year': 9,
        'title': 'Consider a US engineering centre',
        'revenue': 160,
        'headcount': 1000,
        'revenueUs': 56,
        'headcountUs': 250,
        'layout': 'right',
        'waypoint': 'animePulseOverUS',
    },
    {
        'header': 'Listing',
        'title': 'List where it makes most sense for your business - US or Europe',
        'revenue': 200,
        'headcount': 1200,
        'revenueUs': 80,
        'headcountUs': 360,
        'layout': 'left',
        'displayTable': 'true',
        'isListingData': 'true',
        'waypoint': 'animeFireworks',
    },
]
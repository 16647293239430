import Vue from 'vue'

let ivJourneyOverview = Vue.component('iv-journey-overview', {
    template: '#iv-journey-overview',
    props: ['archetype'],
    delimiters: ['[[', ']]'],
    methods: {
        calcPercent(x, y) {
            if (y === 0) {
                return 0
            } else {
                return Math.round(x / y * 100)
            }
        },
    },
    computed: {
        seriesAData() {
            for (let section of this.$props.archetype.sections) {
                if (section.hasOwnProperty('isSeriesAData')) {
                    return section
                }
            }
        },

        seriesARevenue() {
            let total = this.seriesAData.revenue
            let us = this.seriesAData.revenueUs
            let eu = total - us

            return {
                total: total,
                us: us,
                eu: eu,
                usPercent: this.calcPercent(us, total),
                usBarPercent: this.calcPercent(us, this.listingRevenue.total),
                euPercent: this.calcPercent(eu, total),
                euBarPercent: this.calcPercent(eu, this.listingRevenue.total),
            }
        },

        seriesAHeadcount() {
            let total = this.seriesAData.headcount
            let us = this.seriesAData.headcountUs
            let eu = total - us

            return {
                total: total,
                us: us,
                eu: eu,
                usPercent: this.calcPercent(us, total),
                usBarPercent: this.calcPercent(us, this.listingHeadcount.total),
                euPercent: this.calcPercent(eu, total),
                euBarPercent: this.calcPercent(eu, this.listingHeadcount.total),
            }
        },

        listingData() {
            for (let section of this.$props.archetype.sections) {
                if (section.hasOwnProperty('isListingData')) {
                    return section
                }
            }
        },

        listingRevenue() {
            let total = this.listingData.revenue
            let us = this.listingData.revenueUs
            let eu = total - us

            return {
                total: total,
                us: us,
                eu: eu,
                usPercent: this.calcPercent(us, total),
                usBarPercent: this.calcPercent(us, total),
                euPercent: this.calcPercent(eu, total),
                euBarPercent: this.calcPercent(eu, total),
            }
        },

        listingHeadcount() {
            let total = this.listingData.headcount
            let us = this.listingData.headcountUs
            let eu = total - us

            return {
                total: total,
                us: us,
                eu: eu,
                usPercent: this.calcPercent(us, total),
                usBarPercent: this.calcPercent(us, total),
                euPercent: this.calcPercent(eu, total),
                euBarPercent: this.calcPercent(eu, total),
            }
        },

        // todo: can probably remove everything to do with Series A now.
        seriesA() {
            return {
                revenue: {
                    title: 'Revenue',
                    us: {
                        label: `${this.seriesARevenue.usPercent}%`,
                        barStyle: `width:${this.seriesARevenue.usBarPercent}%;`,
                        labelReversed: this.seriesARevenue.usBarPercent > 50,
                    },
                    eu: {
                        label: `${this.seriesARevenue.euPercent}%`,
                        barStyle: `width:${this.seriesARevenue.euBarPercent}%;`,
                        labelReversed: this.seriesARevenue.euBarPercent > 50,
                    },
                    total: `$${this.seriesARevenue.total}m`,
                },
                headcount: {
                    title: 'Headcount',
                    us: {
                        label: `${this.seriesAHeadcount.usPercent}%`,
                        barStyle: `width:${this.seriesAHeadcount.usBarPercent}%;`,
                        labelReversed: this.seriesAHeadcount.usBarPercent > 50,
                    },
                    eu: {
                        label: `${this.seriesAHeadcount.euPercent}%`,
                        barStyle: `width:${this.seriesAHeadcount.euBarPercent}%;`,
                        labelReversed: this.seriesAHeadcount.euBarPercent > 50,
                    },
                    total: this.seriesAHeadcount.total,
                },
            }
        },

        listing() {
            return {
                revenue: {
                    title: 'Revenue',
                    us: {
                        label: `${this.listingRevenue.usPercent}%`,
                        barStyle: `width:${this.listingRevenue.usBarPercent}%;`,
                        labelReversed: this.listingRevenue.usBarPercent > 50,
                    },
                    eu: {
                        label: `${this.listingRevenue.euPercent}%`,
                        barStyle: `width:${this.listingRevenue.euBarPercent}%;`,
                        labelReversed: this.listingRevenue.euBarPercent > 50,
                    },
                    total: `$${this.listingRevenue.total}m`,
                },
                headcount: {
                    title: 'Headcount',
                    us: {
                        label: `${this.listingHeadcount.usPercent}%`,
                        barStyle: `width:${this.listingHeadcount.usBarPercent}%;`,
                        labelReversed: this.listingHeadcount.usBarPercent > 50,
                    },
                    eu: {
                        label: `${this.listingHeadcount.euPercent}%`,
                        barStyle: `width:${this.listingHeadcount.euBarPercent}%;`,
                        labelReversed: this.listingHeadcount.euBarPercent > 50,
                    },
                    total: this.listingHeadcount.total,
                },
            }
        },
    },
})

export {ivJourneyOverview}
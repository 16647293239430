// TAM % -> GTM or Ops Intensity -> Regulation or Localisation -> Competitor = Archetype
let decisionTree = {
    '50-100': {
        'minimal': {
            'yes': {
                'yes': '',
                'no': '',
            },
            'no': {
                'yes': '',
                'no': '',
            },
        },
        'significant': {
            'yes': {
                'yes': '',
                'no': '',
            },
            'no': {
                'yes': '',
                'no': '',
            },
        },
    },
    '30-50': {
        'minimal': {
            'yes': {
                'yes': '',
                'no': '',
            },
            'no': {
                'yes': '',
                'no': '',
            },
        },
        'significant': {
            'yes': {
                'yes': '',
                'no': '',
            },
            'no': {
                'yes': '',
                'no': '',
            },
        },
    },
    '15-30': {
        'minimal': {
            'yes': {
                'yes': '',
                'no': '',
            },
            'no': {
                'yes': '',
                'no': '',
            },
        },
        'significant': {
            'yes': {
                'yes': '',
                'no': '',
            },
            'no': {
                'yes': '',
                'no': '',
            },
        },
    },
}


decisionTree['50-100']['minimal']['yes']['yes'] = 'telescope'
decisionTree['50-100']['minimal']['yes']['no'] = 'telescope'
decisionTree['50-100']['minimal']['no']['yes'] = 'telescope'
decisionTree['50-100']['minimal']['no']['no'] = 'telescope'
decisionTree['50-100']['significant']['yes']['yes'] = 'pendulum'
decisionTree['50-100']['significant']['yes']['no'] = 'pendulum'
decisionTree['50-100']['significant']['no']['yes'] = 'compass'
decisionTree['50-100']['significant']['no']['no'] = 'compass'

decisionTree['30-50']['minimal']['yes']['yes'] = 'telescope'
decisionTree['30-50']['minimal']['yes']['no'] = 'telescope'
decisionTree['30-50']['minimal']['no']['yes'] = 'telescope'
decisionTree['30-50']['minimal']['no']['no'] = 'telescope'
decisionTree['30-50']['significant']['yes']['yes'] = 'anchor'
decisionTree['30-50']['significant']['yes']['no'] = 'anchor'
decisionTree['30-50']['significant']['no']['yes'] = 'anchor'
decisionTree['30-50']['significant']['no']['no'] = 'pendulum'

decisionTree['15-30']['minimal']['yes']['yes'] = 'telescope'
decisionTree['15-30']['minimal']['yes']['no'] = 'telescope'
decisionTree['15-30']['minimal']['no']['yes'] = 'telescope'
decisionTree['15-30']['minimal']['no']['no'] = 'telescope'
decisionTree['15-30']['significant']['yes']['yes'] = 'anchor'
decisionTree['15-30']['significant']['yes']['no'] = 'anchor'
decisionTree['15-30']['significant']['no']['yes'] = 'anchor'
decisionTree['15-30']['significant']['no']['no'] = 'anchor'

export default decisionTree
export default {
    refocusUs: {},
    pulseOver: {
        sf: {
            el: '#anime__pulse-over-sf',
            show: {},
            hide: {},
            highlight: {},
        },
        austin: {
            el: '#anime__pulse-over-austin',
            show: {},
            hide: {},
            highlight: {},
        },
        us: {
            el: '#anime__pulse-over-us',
            show: {},
            hide: {},
            highlight: {},
        },
        france: {
            el: '#anime__pulse-over-france',
            show: {},
            hide: {},
            highlight: {},
        },
        uk: {
            el: '#anime__pulse-over-uk',
            show: {},
            hide: {},
            highlight: {},
        },
        spain: {
            el: '#anime__pulse-over-spain',
            show: {},
            hide: {},
            highlight: {},
        },
    },

    fireworks: {},
    travelLoop: {
        1: {
            el: '#anime__travel-loop',
            width: 448,
            height: 55,
            show: {},
            showX2: {},
        },
        2: {
            el: '#anime__travel-loop-sm',
            width: 448,
            height: 55,
            show: {},
            showX2: {},
        },
    },
    foreignHire: {
        1: {
            width: 500,
            height: 104,
            x: 0,
            y: 0,
            show: {},
        },
        2: {
            width: 500,
            height: 104,
            x: 0,
            y: 0,
            show: {},
        },
        3: {
            width: 500,
            height: 104,
            x: 0,
            y: 0,
            show: {},
        },
        4: {
            width: 500,
            height: 104,
            x: 0,
            y: 0,
            show: {},
        },
        5: {
            width: 500,
            height: 104,
            x: 0,
            y: 0,
            show: {},
        },
        6: {
            width: 500,
            height: 104,
            x: 0,
            y: 0,
            show: {},
        },
    },

    peopleBackground1: {
        el: '#anime__people #people_background_1',
        x: 0,
        x2: 0,
        y: 0,
        y2: 0,
        width: 0,
        height: 0,
        opacity: 0,
    },

    peopleBackground2: {
        el: '#anime__people #people_background_2',
        x: 0,
        x2: 0,
        y: 0,
        y2: 0,
        width: 0,
        height: 0,
        opacity: 0,
    },

    peopleConf: {
        rx: 15,
    },

    people: {
        // Group -1
        leadershipInEurope: {
            el: '#anime__people #leadership_in_europe',
            title: 'Leadership in Europe',
            width: 170,
            height: 30,
            telescopeColorStyle: 4,
            compassColorStyle: 4,
            pendulumColorStyle: 4,
            anchorColorStyle: 4,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: -1,
            show: {},
            hide: {},
            highlight: {},
        },
        leadershipInUs: {
            el: '#anime__people #leadership_in_us',
            title: 'Leadership in US',
            width: 142,
            height: 30,
            telescopeColorStyle: 4,
            compassColorStyle: 4,
            pendulumColorStyle: 4,
            anchorColorStyle: 4,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: -1,
            show: {},
            hide: {},
            highlight: {},
        },
        // Group 0
        founderCeo: {
            el: '#anime__people #founder_ceo',
            title: 'Founder CEO',
            width: 114,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 0,
            show: {},
            hide: {},
            highlight: {},
        },
        founderCeoSplit: {
            el: '#anime__people #founder_ceo_split',
            title: 'Founder CEO',
            width: 114,
            height: 30,
            telescopeColorStyle: 3,
            compassColorStyle: 3,
            pendulumColorStyle: 3,
            anchorColorStyle: 3,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 0,
            show: {},
            hide: {},
            highlight: {},
            trail: {},
        },
        founderCeoSplit2: {
            el: '#anime__people #founder_ceo_split2',
            title: 'Founder CEO',
            width: 114,
            height: 30,
            telescopeColorStyle: 3,
            compassColorStyle: 3,
            pendulumColorStyle: 3,
            anchorColorStyle: 3,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 0,
            show: {},
            hide: {},
            highlight: {},
            trail: {},
        },
        founderCto: {
            el: '#anime__people #founder_cto',
            title: 'Founder CTO',
            width: 114,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 0,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        // Group 1
        cto: {
            el: '#anime__people #cto',
            title: 'CTO',
            width: 51,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 1,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        cpo: {
            el: '#anime__people #cpo',
            title: 'CPO',
            width: 51,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 1,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        cro: {
            el: '#anime__people #cro',
            title: 'CRO',
            width: 53,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 1,
            telescopeColorStyle: 2,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        cro2: {
            el: '#anime__people #cro2',
            title: 'CRO',
            width: 53,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 1,
            telescopeColorStyle: 2,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        cmo: {
            el: '#anime__people #cmo',
            title: 'CMO',
            width: 57,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 1,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        cfo: {
            el: '#anime__people #cfo',
            title: 'CFO',
            width: 51,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 1,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        usPresident: {
            el: '#anime__people #us_president',
            title: 'US President',
            width: 112,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        // Group 2
        vpEngineering: {
            el: '#anime__people #vp_engineering',
            title: 'VP Engineering',
            width: 128,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpProduct: {
            el: '#anime__people #vp_product',
            title: 'VP Product',
            width: 100,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpSales: {
            el: '#anime__people #vp_sales',
            title: 'VP Sales',
            width: 81,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpBizDev: {
            el: '#anime__people #vp_biz_dev',
            title: 'VP Biz Dev',
            width: 101,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpGrowth: {
            el: '#anime__people #vp_growth',
            title: 'VP Growth',
            width: 98,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpMarketing: {
            el: '#anime__people #vp_marketing',
            title: 'VP Marketing',
            width: 116,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpInsideSales: {
            el: '#anime__people #vp_inside_sales',
            title: 'VP Inside Sales',
            width: 128,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 2,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpCustomerSuccess: {
            el: '#anime__people #vp_customer_success',
            title: 'VP Customer Success',
            width: 173,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 2,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        vpCustomerSupport: {
            el: '#anime__people #vp_customer_support',
            title: 'VP Customer Support',
            width: 173,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 2,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },

        // Group 3
        productDirector: {
            el: '#anime__people #product_director',
            title: 'Product Director',
            width: 134,
            height: 30,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 3,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            show: {},
            hide: {},
            highlight: {},
        },
        gmEurope: {
            el: '#anime__people #gm_europe',
            title: 'GM Europe',
            width: 100,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 3,
            show: {},
            hide: {},
            highlight: {},
        },
        gmEMEA: {
            el: '#anime__people #gm_emea',
            title: 'GM EMEA',
            width: 93,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 3,
            show: {},
            hide: {},
            highlight: {},
        },
        gmFrance: {
            el: '#anime__people #gm_france',
            title: 'GM France',
            width: 95,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 3,
            show: {},
            hide: {},
            highlight: {},
        },
        gmSpain: {
            el: '#anime__people #gm_spain',
            title: 'GM Spain',
            width: 89,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 3,
            show: {},
            hide: {},
            highlight: {},
        },
        gmUk: {
            el: '#anime__people #gm_uk',
            title: 'GM UK',
            width: 70,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 3,
            show: {},
            hide: {},
            highlight: {},
        },
        landingTeam: {
            el: '#anime__people #landing_team',
            title: 'Landing Team',
            width: 120,
            height: 30,
            telescopeColorStyle: 1,
            compassColorStyle: 1,
            pendulumColorStyle: 1,
            anchorColorStyle: 1,
            x: 0,
            y: 0,
            active: false,
            moveSpeed: 'default',
            isHidden: true,
            group: 3,
            show: {},
            hide: {},
            highlight: {},
        },
    },
}
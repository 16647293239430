import colors from './data/colors.data'

export default {
    refocusUS(app) {
        let el = '#anime__refocus-us'
        let us = '#archetypes__map-us'
        let eu = '#archetypes__map-eu'

        app.anime.refocusUs = app.$anime.timeline({
            autoplay: false,
            loop: false,
        })
            .add({
                targets: eu,
                fill: '#232323',
                easing: 'easeOutExpo',
                duration: 750,
            }, 0)
            .add({
                targets: us,
                fill: '#404040',
                easing: 'easeOutExpo',
                duration: 750,
            }, 0)
            .add({
                targets: el,
                transformOrigin: 'center center',
                scale: [1, 0],
                opacity: [0, 1],
                easing: 'easeOutQuart',
                duration: 4000,
            }, 0)
            .add({
                targets: el,
                opacity: 0,
                easing: 'easeOutExpo',
                duration: 100,
            }, 3900)
            .add({
                targets: eu,
                fill: '#343434',
                easing: 'easeOutExpo',
                duration: 750,
            }, 4000)
            .add({
                targets: us,
                fill: '#343434',
                easing: 'easeOutExpo',
                duration: 750,
            }, 4000)
    },

    pulseOver(app) {
        for (let p in app.anime.pulseOver) {
            let pulses = `${app.anime.pulseOver[p].el} .pulse-over`
            let pulsesMask = `${app.anime.pulseOver[p].el} .pulse-over-mask`

            app.anime.pulseOver[p].show = app.$anime.timeline({
                autoplay: false,
                loop: false,
            })
                .add({
                    targets: pulses,
                    transformOrigin: 'center center',
                    scale: [0, 1],
                    opacity: [1, 0],
                    easing: 'easeInOutQuart',
                    duration: 3000,
                    delay: app.$anime.stagger(150),
                }, 0)
                .add({
                    targets: pulsesMask,
                    transformOrigin: 'center center',
                    scale: [0, 1],
                    easing: 'easeInOutQuart',
                    duration: 3000,
                }, 150 * 4)
        }
    },

    makeTravelLoop(app, el) {
        let rocket = `${el} .rocket`
        let startCircle = `${el} .start-circle`
        let trail1 = `${el} .trail-1`
        let trail2 = `${el} .trail-2`
        let path1 = app.$anime.path(`${el} .trail-1`)
        let path2 = app.$anime.path(`${el} .trail-2`)
        let stops1 = `${el} .trail-1-stroke stop`
        let stops2 = `${el} .trail-2-stroke stop`

        return app.$anime.timeline({
            autoplay: false,
            loop: false,
        })
            .add({
                targets: [rocket, startCircle],
                r: [0, 8],
                opacity: [0, 1],
                duration: 200,
                easing: 'easeInSine',
            }, 0)
            .add({
                targets: trail1,
                strokeDashoffset: [app.$anime.setDashoffset, 0],
                duration: 1200,
                easing: 'easeInSine',
            }, 200)
            .add({
                targets: rocket,
                cx: path1('x'),
                cy: path1('y'),
                duration: 1200,
                easing: 'easeInSine',
            }, 200)
            .add({
                targets: trail2,
                strokeDashoffset: [app.$anime.setDashoffset, 0],
                duration: 1200,
                easing: 'easeOutSine',
            }, 1400)
            .add({
                targets: rocket,
                cx: path2('x'),
                cy: path2('y'),
                duration: 1200,
                easing: 'easeOutSine',
            }, 1400)
            .add({
                targets: stops1,
                stopOpacity: [1, 0],
                duration: 1200,
                easing: 'linear',
                delay: app.$anime.stagger(200),
            }, 800)
            .add({
                targets: stops2,
                stopOpacity: [1, 0],
                duration: 1200,
                easing: 'linear',
                delay: app.$anime.stagger(200),
            }, 1800)
            .add({
                targets: [rocket, startCircle],
                r: [8, 0],
                opacity: [1, 0],
                duration: 200,
                easing: 'easeInSine',
            }, 4300)
    },

    travelLoop(app) {
        // We can't just say showX2 = show because it will be a reference to the same object, then both will get the double loop.
        app.anime.travelLoop[1].show = this.makeTravelLoop(app, app.anime.travelLoop[1].el)
        app.anime.travelLoop[1].showX2 = this.makeTravelLoop(app, app.anime.travelLoop[1].el)
        app.anime.travelLoop[1].showX2.loop = 2

        // Phone
        app.anime.travelLoop[2].show = this.makeTravelLoop(app, app.anime.travelLoop[2].el)
        app.anime.travelLoop[2].showX2 = this.makeTravelLoop(app, app.anime.travelLoop[2].el)
        app.anime.travelLoop[2].showX2.loop = 2
    },

    foreignHire(app) {
        for (let i in app.anime.foreignHire) {
            let rocket = `#anime__foreign-hire .rocket-${i}`
            let trail = `#anime__foreign-hire .trail-${i}`
            let path = app.$anime.path(`#anime__foreign-hire .trail-${i}`)
            let mask = `#anime__foreign-hire #foreign-hire-mask-gradient-${i}`
            let foreignHireWidth = 520

            app.anime.foreignHire[i].show = app.$anime.timeline({
                autoplay: false,
                loop: false,
            })
                .add({
                    targets: rocket,
                    r: [0, 8],
                    opacity: [0, 1],
                    duration: 200,
                    easing: 'easeInSine',
                }, 0)
                .add({
                    targets: trail,
                    opacity: [0, 1],
                    duration: 200,
                    easing: 'easeInSine',
                }, 0)
                .add({
                    targets: trail,
                    strokeDashoffset: [app.$anime.setDashoffset, 0],
                    duration: 2000,
                    easing: 'easeInSine',
                }, 200)
                .add({
                    targets: rocket,
                    cx: path('x'),
                    cy: path('y'),
                    duration: 2000,
                    easing: 'easeInSine',
                }, 200)
                .add({
                    targets: mask,
                    width: path('x'),
                    duration: 2000,
                    easing: 'easeInSine',
                }, 200)
                .add({
                    targets: mask,
                    translateX: [0, foreignHireWidth],
                    duration: 1200,
                    easing: 'easeOutSine',
                }, 2000)
                .add({
                    targets: rocket,
                    r: [8, 0],
                    opacity: [1, 0],
                    duration: 200,
                    easing: 'easeInSine',
                }, 2200)
                .add({
                    targets: trail,
                    opacity: [1, 0],
                    duration: 200,
                    easing: 'easeInSine',
                }, 2200)
        }
    },

    fireworks(app) {
        let fireworks = '#anime__fireworks'
        let bang1 = '#anime__fireworks #bang1'
        let bang2 = '#anime__fireworks #bang2'
        let bang3 = '#anime__fireworks #bang3'
        let bang4 = '#anime__fireworks #bang4'
        let bang5 = '#anime__fireworks #bang5'
        let bang6 = '#anime__fireworks #bang6'

        app.anime.fireworks = app.$anime.timeline({
            autoplay: false,
            loop: false,
        })
            .add({
                targets: fireworks,
                opacity: [0, 1],
                duration: 100,
                easing: 'easeInQuart',
            }, 0)

        for (let i = 1; i <= 13; i++) {
            let trail = `#anime__fireworks #trail${i}`
            let path = app.$anime.path(`#anime__fireworks #trail${i}`)
            let rocket = `#anime__fireworks #rocket${i}`
            let stops = `#anime__fireworks #fade${i} stop`
            let offset = 200 * (i - 1)
            let duration = 1600 + Math.random() * 400

            app.anime.fireworks
                .add({
                    targets: rocket,
                    cx: path('x'),
                    cy: path('y'),
                    duration: duration,
                    easing: 'easeInQuart',
                }, offset)
                .add({
                    targets: stops,
                    stopOpacity: [1, 0],
                    duration: duration - 400,
                    easing: 'linear',
                    delay: app.$anime.stagger(400),
                }, offset)
                .add({
                    targets: trail,
                    strokeDashoffset: [app.$anime.setDashoffset, 0],
                    duration: duration,
                    easing: 'easeInQuart',
                }, offset)
                .add({
                    targets: rocket,
                    opacity: [1, 0],
                    duration: 400,
                    easing: 'easeInQuart',
                }, offset + duration - 400)
        }

        app.anime.fireworks.add({
            targets: [bang1, bang2, bang3, bang4, bang5, bang6],
            opacity: [1, 0],
            scale: [0, 2],
            duration: 1000,
            easing: 'easeOutQuart',
            delay: app.$anime.stagger(400),
        }, 2000)

    },

    newPeople(app) {
        for (let p in app.anime.people) {
            let person = app.anime.people[p].el
            let mask = `${app.anime.people[p].el} .person-mask`
            let background = `${app.anime.people[p].el}  .person-background`
            let text = `${app.anime.people[p].el} .person-text`

            let style = {
                background: {
                    standard: {
                        fill: colors.grey70,
                        fillOpacity: 1,
                        stroke: colors.grey70,
                    },
                    highlight: {
                        fill: colors.red,
                        fillOpacity: 1,
                        stroke: colors.red,
                    },
                },
                text: {
                    standard: {
                        fill: colors.grey40,
                    },
                    highlight: {
                        fill: colors.grey0,
                    },
                },
            }

            // telescope has some different colors
            let colorStyle = 1
            if (app.isTelescope) {
                colorStyle = app.anime.people[p].telescopeColorStyle
            }
            if (app.isCompass) {
                colorStyle = app.anime.people[p].compassColorStyle
            }
            if (app.isPendulum) {
                colorStyle = app.anime.people[p].pendulumColorStyle
            }
            if (app.isAnchor) {
                colorStyle = app.anime.people[p].anchorColorStyle
            }
            switch (colorStyle) {
                case 2:
                    style.background.highlight.fill = colors.grey20
                    style.background.standard.fill = colors.grey40

                    style.background.highlight.stroke = colors.grey20
                    style.background.standard.stroke = colors.grey40

                    style.text.highlight.fill = colors.grey80
                    style.text.standard.fill = colors.grey70
                    break
                case 3:
                    style.background.highlight.fill = 'rgb(24, 24, 24)'
                    style.background.highlight.fillOpacity = 0.6
                    style.background.standard.fill = 'rgb(24, 24, 24)'
                    style.background.standard.fillOpacity = 0.6

                    style.background.highlight.stroke = colors.red
                    style.background.standard.stroke = colors.grey40

                    style.text.highlight.fill = colors.grey0
                    style.text.standard.fill = colors.grey40
                    break
                case 4:
                    // Leadership titles
                    style.background.highlight.fill = 'rgb(24, 24, 24)'
                    style.background.highlight.fillOpacity = 0.9
                    style.background.standard.fill = 'rgb(24, 24, 24)'
                    style.background.standard.fillOpacity = 0.9

                    style.background.highlight.stroke = colors.grey70
                    style.background.standard.stroke = colors.grey70

                    style.text.highlight.fill = colors.grey0
                    style.text.standard.fill = colors.grey40
                    break
                default:
                    break
            }


            // Compact UI settings
            let pillPosition = {
                x: 3,
                // Should be 3 but 2 centers the text better
                y: 2,
                width: app.anime.people[p].width - 6,
                height: app.anime.people[p].height - 6,
            }

            if (!app.isPhone()) {
                pillPosition = {
                    x: 0,
                    y: 0,
                    width: app.anime.people[p].width,
                    height: app.anime.people[p].height,
                }
            }

            if (p === 'founderCeoSplit' || p === 'founderCeoSplit2') {
                // Founder ceo split has a different, very simple, show animation
                app.anime.people[p].show = app.$anime.timeline({
                    autoplay: false,
                    loop: false,
                    begin: () => {
                        app.anime.people[p].isHidden = false
                    },
                })
                    .add({
                        targets: person,
                        opacity: [0, 1],
                        duration: 100,
                    }, 0)
                    .add({
                        targets: background,
                        fill: style.background.highlight.fill,
                        fillOpacity: style.background.highlight.fillOpacity,
                        stroke: style.background.highlight.stroke,
                        duration: 0,
                    }, 0)
                    .add({
                        targets: text,
                        fill: style.text.highlight.fill,
                        duration: 0,
                    }, 0)
                    .add({
                        targets: [background, mask],
                        // Width equal to height is correct so it makes a circle
                        width: [0, pillPosition.height],
                        height: [0, pillPosition.height],
                        x: [(pillPosition.width / 2), ((pillPosition.width / 2) - (pillPosition.height / 2))],
                        y: [(30 / 2), pillPosition.y],
                        easing: 'easeInQuart',
                        duration: 99,
                    }, 1)
                    .add({
                        targets: text,
                        opacity: 1,
                        easing: 'easeInOutQuart',
                        duration: 100,
                    }, 0)
                    .add({
                        targets: [background, mask],
                        width: pillPosition.width,
                        x: pillPosition.x,
                        easing: 'easeOutQuart',
                        duration: 100,
                    }, 100)
                    .add({
                        targets: background,
                        fill: style.background.standard.fill,
                        fillOpacity: style.background.standard.fillOpacity,
                        stroke: style.background.standard.stroke,
                        easing: 'easeInOutQuart',
                        duration: 1000,
                    }, 3000)
                    .add({
                        targets: text,
                        fill: style.text.standard.fill,
                        easing: 'easeInOutQuart',
                        duration: 1000,
                    }, 3000)
            } else {
                app.anime.people[p].show = app.$anime.timeline({
                    autoplay: false,
                    loop: false,
                    begin: () => {
                        app.anime.people[p].isHidden = false
                    },
                })
                    .add({
                        targets: person,
                        opacity: [0, 1],
                        duration: 100,
                    }, 0)
                    .add({
                        targets: background,
                        fill: style.background.highlight.fill,
                        fillOpacity: style.background.highlight.fillOpacity,
                        stroke: style.background.highlight.stroke,
                        duration: 0,
                    }, 0)
                    .add({
                        targets: text,
                        fill: style.text.highlight.fill,
                        duration: 0,
                    }, 0)
                    .add({
                        targets: [background, mask],
                        // Width equal to height is correct so it makes a circle
                        width: [0, pillPosition.height],
                        height: [0, pillPosition.height],
                        x: [(pillPosition.width / 2), ((pillPosition.width / 2) - (pillPosition.height / 2))],
                        y: [(30 / 2), pillPosition.y],
                        easing: 'easeInQuart',
                        duration: 499,
                    }, 1)
                    .add({
                        targets: text,
                        opacity: 1,
                        easing: 'easeInOutQuart',
                        duration: 500,
                    }, 250)
                    .add({
                        targets: [background, mask],
                        width: pillPosition.width,
                        x: pillPosition.x,
                        easing: 'easeOutElastic(1, .5)',
                        duration: 500,
                    }, 500)
                    .add({
                        targets: background,
                        fill: style.background.standard.fill,
                        fillOpacity: style.background.standard.fillOpacity,
                        stroke: style.background.standard.stroke,
                        easing: 'easeInOutQuart',
                        duration: 1000,
                    }, 3000)
                    .add({
                        targets: text,
                        fill: style.text.standard.fill,
                        easing: 'easeInOutQuart',
                        duration: 1000,
                    }, 3000)
            }

            // Hide animations
            app.anime.people[p].hide = app.$anime.timeline({
                autoplay: false,
                loop: false,
                complete: () => {
                    app.anime.people[p].isHidden = true
                },
            })
                .add({
                    targets: person,
                    opacity: [1, 0],
                    easing: 'easeOutQuart',
                    duration: 500,
                }, 0)

            // Highlight animations
            app.anime.people[p].highlight = app.$anime.timeline({
                autoplay: false,
                loop: false,
            })
                .add({
                    targets: background,
                    fill: [style.background.standard.fill, style.background.highlight.fill],
                    fillOpacity: [style.background.standard.fillOpacity, style.background.highlight.fillOpacity],
                    stroke: [style.background.standard.stroke, style.background.highlight.stroke],
                    easing: 'easeInOutQuart',
                    duration: 500,
                }, 0)
                .add({
                    targets: text,
                    fill: [style.text.standard.fill, style.text.highlight.fill],
                    easing: 'easeInOutQuart',
                    duration: 500,
                }, 0)
                .add({
                    targets: background,
                    fill: [style.background.highlight.fill, style.background.standard.fill],
                    fillOpacity: [style.background.highlight.fillOpacity, style.background.standard.fillOpacity],
                    stroke: [style.background.highlight.stroke, style.background.standard.stroke],
                    easing: 'easeInOutQuart',
                    duration: 1000,
                }, 3000)
                .add({
                    targets: text,
                    fill: [style.text.highlight.fill, style.text.standard.fill],
                    easing: 'easeInOutQuart',
                    duration: 1000,
                }, 3000)

        }

        for (let s of ['founderCeoSplit', 'founderCeoSplit2']) {
            let trail = `${app.anime.people[s].el} .person-trail`
            let gradient = `${app.anime.people[s].el} .trail_gradient`

            app.anime.people[s].trail = app.$anime.timeline({
                autoplay: false,
                loop: false,
            })
                .add({
                    targets: trail,
                    width: [0, 100],
                    easing: 'easeInQuart',
                    duration: 500,
                }, 0)
                .add({
                    targets: gradient,
                    x2: [114, 214],
                    easing: 'easeInQuart',
                    duration: 500,
                }, 0)
                .add({
                    targets: trail,
                    width: [100, 0],
                    easing: 'easeOutQuart',
                    duration: 500,
                }, 1000)
                .add({
                    targets: gradient,
                    x2: [214, 114],
                    easing: 'easeOutQuart',
                    duration: 500,
                }, 1000)
        }
    },


    createAll(app) {
        console.time('createAll')
        this.refocusUS(app)
        this.pulseOver(app)
        this.travelLoop(app)
        this.foreignHire(app)
        this.fireworks(app)
        console.timeEnd('createAll')
    },

    // This is different now for telescope
    createNewPeople(app) {
        console.time('createNewPeople')
        this.newPeople(app)
        console.timeEnd('createNewPeople')
    },
}
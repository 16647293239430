import $ from 'jquery'
import Vue from 'vue'
import VueAnime from 'vue-animejs'
import VueWaypoint from 'vue-waypoint'
import VTooltip from 'v-tooltip'

import config from './data/archetypes.data'
import { ivShare } from './components/iv-share'
import { ivJourneySection } from './components/iv-journey-section'
import { ivJourneyOverview } from './components/iv-journey-overview'
import { ivFooterCharts } from './components/iv-footer-charts'
import { debounce, throttle } from 'lodash'
import animations from './animations'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import attachSocialShareEvents from '../../../ui/js/utils/attachSocialShareEvents'
import Bugsnag from '@bugsnag/js'
import bugsnagConfig from '../../../ui/js/utils/bugsnagConfig'

export default {
    /**
     * Class initialiser (on load).
     */
    init: function () {
        Vue.use(VueAnime)
        Vue.use(VueWaypoint)
        Vue.use(VTooltip, {
            defaultContainer: '.archetypes__introduction',
            disposeTimeout: 0,
        })
        Bugsnag.start(bugsnagConfig)
        Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
        window.expansionPlan = new Vue({
            el: '#vue-archetypes',
            delimiters: ['[[', ']]'],
            components: {
                ivShare,
                ivJourneySection,
                ivJourneyOverview,
                ivFooterCharts,
            },

            data: config,

            created() {
                this.route.url = window.location.href
                // Set all the default answers.
                for (const key in this.quiz) {
                    this.trackedAnswers[key] = this.quiz[key].defaultAnswer
                    this.quiz[key].answer = this.quiz[key].defaultAnswer
                }

                // Scroll handler
                window.addEventListener('scroll', throttle(this.onScroll, 250, {
                    'leading': false,
                    'trailing': true,
                }))
                this.onScroll()

                // Resize handler
                window.addEventListener('resize', debounce(this.onResize, 250))
                this.onCreated()

                this.makeYears()

                window.onbeforeunload = () => {
                    this.enableScroll()
                }

                this.bodyEl = document.querySelector('body')
            },

            mounted() {
                this.setPeopleConf()
                animations.createAll(this)
            },

            computed: {
                answers() {
                    if (typeof window.gtag !== 'undefined') {
                        let keyTranslations = {
                            1: 'Total-Addressable-Market',
                            2: 'Go-to-Market',
                            3: 'Operations',
                            4: 'Regulations',
                            5: 'Localisation',
                            6: 'Competition',
                        }
                        for (const key in this.trackedAnswers) {
                            if (this.trackedAnswers[key] !== this.quiz[key].answer) {
                                let event = {
                                    'event_category': 'Expansion Plan',
                                    'event_label': 'Inputs Change',
                                }
                                event[keyTranslations[key]] = this.quiz[key].answer
                                window.gtag('event', 'value_change', event)
                                this.trackedAnswers[key] = this.quiz[key].answer
                            }
                        }
                    }
                    return {
                        tam: this.quiz[1].answer,
                        gtm: this.quiz[2].answer,
                        ops: this.quiz[3].answer,
                        reg: this.quiz[4].answer,
                        loc: this.quiz[5].answer,
                        com: this.quiz[6].answer,
                    }
                },

                decisions() {
                    let a = this.answers
                    return {
                        tam: a.tam,
                        gtmOps: a.gtm === 'marketing' && a.ops === 'minimal' ? 'minimal' : 'significant',
                        regLoc: a.reg === 'yes' || a.loc === 'yes' ? 'yes' : 'no',
                        com: a.com,
                    }
                },

                myArchetype() {
                    if (this.forcedArchetype !== '') {
                        return this.forcedArchetype
                    } else {
                        let d = this.decisions
                        return this.decisionTree[d.tam][d.gtmOps][d.regLoc][d.com]
                    }
                },

                isAnchor() {
                    let a = this.answers
                    // There are different companies shown for each anchor type.
                    if (this.myArchetype === 'anchor') {
                        return a.tam === '15-30' ? 1 : 2
                    }

                    return 0
                },

                isCompass() {
                    return this.myArchetype === 'compass' ? 1 : 0
                },

                isPendulum() {
                    let a = this.answers
                    // There are different companies shown for each pendulum type.
                    if (this.myArchetype === 'pendulum') {
                        return a.tam === '30-50' ? 1 : 2
                    }

                    return 0
                },

                isTelescope() {
                    return this.myArchetype === 'telescope' ? 1 : 0
                },

                archetype() {
                    return this.archetypes[this.myArchetype] || {}
                },

                yearMax() {
                    return Object.keys(this.archetype.years).slice(-1)[0]
                },

                yearMarkerStyle() {
                    return `top:${24 * (Math.floor(this.activeYear) - 1)}px;`
                },
            },

            methods: {
                animeShow(waypoint, positionId) {
                    if (typeof positionId !== 'undefined') {
                        let positionDirection = this.getPositionDirection(positionId)
                        return (typeof waypoint.direction !== 'undefined' && waypoint.going === this.$waypointMap.GOING_IN && positionDirection === 'up')
                    } else {
                        // iOs gets the direction wrong when GOING_IN the second time you scroll through the site.
                        // testing if we can do without the direction
                        return this.animeGoingIn(waypoint)
                    }
                },

                animeHide(waypoint) {
                    return (typeof waypoint.direction !== 'undefined' && waypoint.going === this.$waypointMap.GOING_OUT && waypoint.direction === this.$waypointMap.DIRECTION_BOTTOM)
                },

                animeGoingIn(waypoint) {
                    return (typeof waypoint.direction !== 'undefined' && waypoint.going === this.$waypointMap.GOING_IN)
                },

                animeGoingOut(waypoint) {
                    return (typeof waypoint.direction !== 'undefined' && waypoint.going === this.$waypointMap.GOING_OUT)
                },

                // Matches breakpoints.scss ep-phone
                isPhone() {
                    return window.innerWidth <= 568
                },

                // Matches breakpoints.scss ep-tablet
                isTablet() {
                    return window.innerWidth >= 569 && window.innerWidth < 1025
                },

                // Matches breakpoints.scss ep-desktop
                isDesktop() {
                    return window.innerWidth >= 1025 && window.innerWidth < 1367
                },

                // Matches breakpoints.scss ep-wide
                isWide() {
                    return window.innerWidth >= 1367
                },

                // Matches breakpoints.scss ep-full-ui
                isFullUi() {
                    return window.innerWidth >= 769
                },

                // Matches breakpoints.scss ep-compact-ui
                isCompactUi() {
                    return !this.isFullUi()
                },

                iconsPaddingBottom() {
                    if (this.isPhone()) {
                        return '40px'
                    } else if (this.isTablet()) {
                        return '60px'
                    }
                    return '80px'
                },

                myArchetypeClick() {
                    if (!this.attachedEvents) {
                        // only attach once in case quiz is retaken
                        attachSocialShareEvents()
                        this.attachedEvents = true
                    }
                    if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'value_change', {
                            'event_category': 'Expansion Plan',
                            'event_label': 'Quizz Result',
                            'value': this.myArchetype,
                        })
                    }
                    for (const key in this.quiz) {
                        this.quiz[key].tooltipOpen = false
                    }

                    // We need to recreate the new people animations because the colors change for telescope
                    // todo: I think we could refactor the color changes to be css class based but that's quite time consuming
                    animations.createNewPeople(this)

                    const iconWrapper = `.archetypes__large-icon__${this.myArchetype}`
                    const iconSvg = `${iconWrapper} svg path`
                    const iconTitle = `${iconWrapper} .archetypes__large-icon-title`
                    const otherIconWrappers = `.archetypes__large-icon:not(${iconWrapper})`
                    const archetypesIntroduction = '.archetypes__introduction'
                    const introductionInnerHeight = `${this.$refs['archetypesIntroductionInner'].offsetHeight}px`
                    const archetypesArchetype = `.archetypes__${this.myArchetype}`
                    const archetypesJourney = '.archetypes__journey'
                    const mainNavigation = 'nav.navigation'
                    const iconsPaddingBottom = this.iconsPaddingBottom()
                    console.log('iconsPaddingBottom', iconsPaddingBottom)

                    // Disable scroll
                    this.disableScroll()

                    // Reset everyone
                    this.resetPeople()

                    // scroll to bottom of page
                    // this.$refs['archetypesLargeIcons'].scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
                    // Safari doesn't scroll smoothly so using jQuery
                    $('html, body').animate({scrollTop: this.$refs['archetypesIntroductionInner'].offsetHeight}, '500')

                    // Hide main nav
                    this.$anime({
                        targets: mainNavigation,
                        top: '-100%',
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Selected archetype title red
                    this.$anime({
                        targets: iconTitle,
                        color: this.colors.red,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })
                    // Selected archetype red
                    this.$anime({
                        targets: iconSvg,
                        fill: this.colors.red,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })
                    // Drop other archetype icons
                    this.$anime({
                        targets: otherIconWrappers,
                        translateY: 200,
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: this.$anime.stagger(1000 / 3),
                    })
                    // Hide other archetype icons.
                    this.$anime({
                        targets: otherIconWrappers,
                        opacity: 0,
                        easing: 'easeOutExpo',
                        duration: 900,
                        delay: 100,
                    })

                    // Moves selected icon to center by making all others zero width.
                    this.$anime({
                        targets: otherIconWrappers,
                        maxWidth: ['500px', '0px'],
                        maxHeight: ['500px', '0px'], // todo might move this  later
                        paddingBottom: [iconsPaddingBottom, '0px'],
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: 1000,
                    })

                    // Hide icon title
                    this.$anime({
                        targets: iconTitle,
                        opacity: 0,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 2000,
                    })

                    // Hide introduction
                    this.$anime({
                        targets: archetypesIntroduction,
                        opacity: 0,
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: 2000,
                    })
                    this.$anime({
                        targets: archetypesIntroduction,
                        maxHeight: [introductionInnerHeight, '0px'],
                        easing: 'easeOutExpo',
                        duration: 2000,
                        delay: 3000,
                    })

                    // Show Archetype
                    this.$anime({
                        targets: archetypesArchetype,
                        opacity: 1,
                        maxHeight: 100000,
                        easing: 'easeOutExpo',
                        duration: 2000,
                        delay: 4500,
                    })

                    // Show Journey
                    this.$anime({
                        targets: archetypesJourney,
                        opacity: 1,
                        maxHeight: 100000,
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: 4500,
                        complete: () => {
                            this.makeArchtypesScrollData()
                        },
                    })

                    // If this happens too early then waypoints can trigger and open footer on screen.
                    setTimeout(() => {
                        this.$refs['archetypesOverviewHeader'].classList.add('active')
                        // Enable all waypoints after animation ends.
                        this.enableWaypoints()
                        // Enable scroll after animation ends.
                        this.enableScroll()
                    }, 5500)
                },

                backToQuestionsClick() {
                    const iconWrapper = `.archetypes__large-icon__${this.myArchetype}`
                    const iconSvg = `${iconWrapper} svg path`
                    const allIconTitles = '.archetypes__large-icon-title'
                    const otherIconWrappers = `.archetypes__large-icon:not(${iconWrapper})`
                    const archetypesIntroduction = '.archetypes__introduction'
                    const introductionInnerHeight = `${this.$refs['archetypesIntroductionInner'].offsetHeight}px`
                    const archetypesArchetype = `.archetypes__${this.myArchetype}`
                    const archetypesJourney = '.archetypes__journey'
                    const mainNavigation = 'nav.navigation'
                    const iconsPaddingBottom = this.iconsPaddingBottom()
                    console.log('iconsPaddingBottom', iconsPaddingBottom)
                    if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'page_view', {
                            'event_category': 'Expansion Plan',
                            'event_label': 'Quizz Retry',
                        })
                    }
                    // Disable scroll
                    this.disableScroll()

                    // Disable all waypoints
                    this.disableWaypoints()

                    // Reset everyone
                    this.resetPeople()

                    // Hide journey ui
                    this.animeJourneyUiHide()

                    // Hide archetypes overview header
                    this.$refs['archetypesOverviewHeader'].classList.remove('active')
                    // Hide archetypes footer
                    this.$refs['archetypesFooter'].classList.remove('active')

                    // Hide Archetype
                    this.$anime({
                        targets: archetypesArchetype,
                        opacity: 0,
                        maxHeight: 0,
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: 0,
                    })
                    // Hide Journey
                    this.$anime({
                        targets: archetypesJourney,
                        opacity: 0,
                        maxHeight: 0,
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: 0,
                    })
                    // Selected archetype back to grey
                    this.$anime({
                        targets: iconSvg,
                        fill: this.colors.brandGrey2,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })
                    // Selected archetype title back to grey
                    this.$anime({
                        targets: allIconTitles,
                        color: this.colors.brandGrey2,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Show introduction
                    this.$anime({
                        targets: archetypesIntroduction,
                        opacity: 1,
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: 2000,
                    })
                    this.$anime({
                        targets: archetypesIntroduction,
                        maxHeight: ['0px', introductionInnerHeight],
                        easing: 'easeOutExpo',
                        duration: 4000,
                        delay: 2000,
                    })

                    // Show icon title
                    this.$anime({
                        targets: allIconTitles,
                        opacity: 1,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 4000,
                    })

                    // Moves selected icon to center by making all others zero width.
                    this.$anime({
                        targets: otherIconWrappers,
                        maxWidth: ['0px', '500px'],
                        maxHeight: ['0px', '500px'],
                        paddingBottom: ['0px', iconsPaddingBottom],
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 4000,
                    })

                    // Show other archetype icons.
                    this.$anime({
                        targets: otherIconWrappers,
                        opacity: 1,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 4500,
                    })

                    // Bring other archetype icons up
                    this.$anime({
                        targets: otherIconWrappers,
                        translateY: 0,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 4500,
                    })

                    // Show main nav
                    this.$anime({
                        targets: mainNavigation,
                        top: '0',
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 5000,
                    })

                    // Don't force archetype
                    setTimeout(() => {
                        // Reset active year
                        this.activeYear = 1
                        // Reset forcedArchetype
                        this.forcedArchetype = ''
                        // Enable scroll after animation ends.
                        this.enableScroll()
                        // Make sure all journey ui is hidden
                        this.$refs['archetypesOverviewHeader'].classList.remove('active')
                        this.$refs['archetypesYears'].classList.remove('active')
                        this.$refs['archetypesJourneyFooter'].classList.remove('active')
                        this.$refs['archetypesJourneyHeader'].classList.remove('active')
                        this.$refs['archetypesFooter'].classList.remove('active')
                        // Reset padding bottom on large icons
                        $('.archetypes__large-icon').css({'padding-bottom': ''})
                    }, 5000)
                },

                backToSummaryClick(event) {
                    // Disable scroll
                    this.disableScroll()

                    // Disable all waypoints
                    this.disableWaypoints()

                    // Reset everyone
                    this.resetPeople()

                    // Hide journey ui
                    this.animeJourneyUiHide()

                    // Make sure the button doesn't remain in active state
                    if (event) {
                        event.target.blur()
                    }

                    // window.scroll({
                    //     top: 0,
                    //     left: 0,
                    //     behavior: 'smooth',
                    // })
                    // Safari doesn't scroll smoothly so using jQuery
                    $('html, body').animate({scrollTop: 0}, '2000')

                    setTimeout(() => {
                        // Reset active year
                        this.activeYear = 1
                        // Enable all waypoints after animation ends.
                        this.enableWaypoints()
                        // Enable scroll after animation ends.
                        this.enableScroll()
                        // Show the journey overview header
                        console.log('Show the journey overview header')
                        this.$refs['archetypesOverviewHeader'].classList.add('active')
                    }, 2000)

                },

                backToStartClick(event) {
                    // todo: back to start animation is a bit messy because we are right down the bottom
                    this.backToQuestionsClick(event)
                },

                forceArchetypeClick(forcedArchetype) {
                    this.backToSummaryClick(false)

                    const archetypesJourney = '.archetypes__journey'
                    let oldArchetype = this.myArchetype
                    this.forcedArchetype = forcedArchetype
                    let newArchetype = this.myArchetype

                    const newIconWrapper = `.archetypes__large-icon__${newArchetype}`
                    const newIconSvg = `${newIconWrapper} svg path`
                    const newIconTitle = `${newIconWrapper} .archetypes__large-icon-title`

                    const oldIconWrapper = `.archetypes__large-icon__${oldArchetype}`
                    const oldIconSvg = `${oldIconWrapper} svg path`
                    const oldIconTitle = `${oldIconWrapper} .archetypes__large-icon-title`
                    const iconsPaddingBottom = this.iconsPaddingBottom()
                    console.log('iconsPaddingBottom', iconsPaddingBottom)

                    animations.createNewPeople(this)

                    // Selected archetype title red
                    this.$anime({
                        targets: newIconTitle,
                        color: this.colors.red,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })
                    // Selected archetype red
                    this.$anime({
                        targets: newIconSvg,
                        fill: this.colors.red,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })
                    // Hide icon title
                    this.$anime({
                        targets: newIconTitle,
                        opacity: 0,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Change old one back to grey
                    this.$anime({
                        targets: oldIconSvg,
                        fill: this.colors.brandGrey2,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Old title back to grey
                    this.$anime({
                        targets: oldIconTitle,
                        color: this.colors.brandGrey2,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Drop other archetype icons
                    this.$anime({
                        targets: oldIconWrapper,
                        translateY: 200,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Hide other archetype icons.
                    this.$anime({
                        targets: oldIconWrapper,
                        opacity: 0,
                        easing: 'easeOutExpo',
                        duration: 400,
                        delay: 0,
                    })

                    // Moves selected icon to center by making all others zero width.
                    this.$anime({
                        targets: oldIconWrapper,
                        maxWidth: ['500px', '0px'],
                        maxHeight: ['500px', '0px'],
                        paddingBottom: [iconsPaddingBottom, '0px'],
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Moves selected icon to center by making all others zero width.
                    this.$anime({
                        targets: newIconWrapper,
                        maxWidth: ['0px', '500px'],
                        maxHeight: ['0px', '500px'],
                        paddingBottom: ['0px', iconsPaddingBottom],
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Show other archetype icons.
                    this.$anime({
                        targets: newIconWrapper,
                        opacity: 1,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })

                    // Bring other archetype icons up
                    this.$anime({
                        targets: newIconWrapper,
                        translateY: 0,
                        easing: 'easeOutExpo',
                        duration: 500,
                        delay: 0,
                    })


                    this.$anime({
                        targets: archetypesJourney,
                        opacity: 0,
                        maxHeight: 0,
                        easing: 'easeOutExpo',
                        duration: 1000,
                        delay: 0,
                    })
                    this.$anime({
                        targets: archetypesJourney,
                        opacity: 1,
                        maxHeight: 100000,
                        easing: 'easeOutExpo',
                        duration: 2000,
                        delay: 1000,
                        complete: () => {
                            this.makeArchtypesScrollData()
                        },
                    })
                },

                enableScroll() {
                    enableBodyScroll(this.bodyEl)
                },

                disableScroll() {
                    disableBodyScroll(this.bodyEl)
                    // Fail safe
                    setTimeout(this.enableScroll, 10000)
                },

                enableWaypoints() {
                    this.waypoints.isActive = true
                },

                disableWaypoints() {
                    this.waypoints.isActive = false
                },

                animeJourneyUi(waypoint) {
                    if (this.animeShow(waypoint)) {
                        this.$refs['archetypesOverviewHeader'].classList.remove('active')
                        this.animeJourneyUiShow()
                        this.$refs['archetypesFooter'].classList.add('active')
                    } else if (this.animeHide(waypoint)) {
                        this.$refs['archetypesOverviewHeader'].classList.add('active')
                        this.animeJourneyUiHide()
                        this.$refs['archetypesFooter'].classList.remove('active')
                    }
                },

                animeJourneyUiShow() {
                    // this.$refs['archetypesOverviewHeader'].classList.remove('active')

                    // todo could show all animations
                    this.$refs['archetypesYears'].classList.add('active')
                    this.$refs['archetypesJourneyFooter'].classList.add('active')
                    this.$refs['archetypesJourneyHeader'].classList.add('active')
                },

                animeJourneyUiHide() {
                    // this.$refs['archetypesOverviewHeader'].classList.add('active')

                    // todo could hide all animations
                    this.$refs['archetypesYears'].classList.remove('active')
                    this.$refs['archetypesJourneyFooter'].classList.remove('active')
                    this.$refs['archetypesJourneyHeader'].classList.remove('active')
                    // Reset people so the overlay vanishes
                    this.resetPeople()
                },

                makeArchtypesScrollData() {
                    this.archetypes[this.myArchetype].scrollData = []
                    for (let s = 0; s < this.archetype.sections.length; s++) {
                        if (typeof this.archetype.sections[s].revenue !== 'undefined'
                            && typeof this.archetype.sections[s].headcount !== 'undefined'
                            && typeof this.archetype.sections[s].revenueUs !== 'undefined'
                            && typeof this.archetype.sections[s].headcountUs !== 'undefined') {

                            let refKey = `${this.myArchetype}Section${s}`
                            if (refKey in this.$refs
                                && typeof this.$refs[refKey][0] !== 'undefined'
                                && typeof this.$refs[refKey][0].$el !== 'undefined'
                                && typeof this.$refs[refKey][0].$el.offsetTop !== 'undefined') {
                                let offsetTop = this.$refs[refKey][0].$el.offsetTop
                                let offsetBottom = offsetTop + this.$refs[refKey][0].$el.offsetHeight

                                this.archetypes[this.myArchetype].scrollData.push({
                                    offsetTop: offsetTop,
                                    offsetBottom: offsetBottom,
                                    sectionId: s,
                                    revenue: this.archetype.sections[s].revenue,
                                    headcount: this.archetype.sections[s].headcount,
                                    revenueUs: this.archetype.sections[s].revenueUs,
                                    headcountUs: this.archetype.sections[s].headcountUs,
                                })
                            }
                        }
                    }
                },

                onCreated() {
                    // Activate year waypoints
                    this.yearWaypoints.isActive = this.isFullUi()
                },

                /**
                 * Window scroll event handler.
                 */
                onScroll() {
                    if ('app' in this.$refs) {
                        if ('journey' in this.$refs) {
                            let journeyTop = this.$refs.app.offsetTop + this.$refs.journey.offsetTop
                            let windowTop = window.pageYOffset
                            let journeyYPos = windowTop - journeyTop
                            journeyYPos = journeyYPos < 0 ? 0 : journeyYPos
                            this.journey.yPos = journeyYPos
                        }
                    }
                },

                onResize() {
                    // Recreate the newPeople animations to get the mobile / desktop sizes
                    animations.createNewPeople(this)

                    this.setPeopleConf()

                    // Move people to current places
                    this.hideAllPeople()
                    setTimeout(() => {
                        this.movePeople(this.journey.positionId)
                        setTimeout(() => {
                            this.showPeople(this.journey.positionId)
                        }, 500)
                    }, 100)

                    // Recalculate the scroll data
                    this.makeArchtypesScrollData()

                    // Activate year waypoints
                    this.yearWaypoints.isActive = this.isFullUi()
                },

                setPeopleConf() {
                    if (this.isPhone()) {
                        this.anime.peopleConf.rx = 12
                    } else {
                        this.anime.peopleConf.rx = 15
                    }
                },

                makeYears() {
                    for (let a in this.archetypes) {
                        if (this.archetypes.hasOwnProperty(a)) {
                            let archetype = this.archetypes[a]
                            for (let section of archetype.sections) {
                                if (section.hasOwnProperty('year')) {
                                    let year = Math.floor(section.year)
                                    archetype.years[year] = year
                                }
                            }
                        }
                    }
                },

                setActiveYearWaypointCallback(waypoint) {
                    if (this.animeGoingIn(waypoint)) {
                        this.activeYear = parseFloat(waypoint.el.dataset.year) || this.activeYear
                    }
                },

                yearIsActive(year) {
                    return year === parseInt(Math.floor(this.activeYear))
                },

                openModal(text) {
                    this.modal.text = text
                    this.modal.show = true
                },

                closeModal() {
                    this.modal.show = false
                    this.modal.text = ''
                },

                // This is a replacement for the waypoint direction which is weird on ios
                // It doesn't replicate the functiionality it just gives us the information we need.
                getPositionDirection(newPositionId) {
                    let oldPositionId = this.journey.positionId
                    let positionDirection = ''

                    if (newPositionId < oldPositionId) {
                        // Scrolling up
                        positionDirection = 'down'
                    } else if (newPositionId === oldPositionId) {
                        positionDirection = 'none'
                    } else if (newPositionId > oldPositionId) {
                        // Scrolling down
                        positionDirection = 'up'
                    }
                    return positionDirection
                },

                setCurrentPosition(positionId) {
                    this.journey.positionId = positionId
                },

                currentPositionIs(positionId) {
                    return this.journey.positionId === positionId
                },

                resetPeople() {
                    this.setCurrentPosition(0)
                    this.hideAllPeople()
                    setTimeout(() => {
                        this.movePeople(this.journey.positionId)
                    }, 1000)
                },

                debugWaypoint(waypoint, positionId) {
                    console.log(`--- ${positionId} ---`)
                    if (this.animeShow(waypoint, positionId)) {
                        console.log('--- show ---')
                    }
                    if (this.animeHide(waypoint)) {
                        console.log('--- hide ---')
                    }
                    if (this.animeGoingIn(waypoint)) {
                        console.log('--- going in ---')
                    }
                    if (this.animeGoingOut(waypoint)) {
                        console.log('--- going out ---')
                    }
                    console.log('================')
                },

                // Generic reposition people controller
                controlPeople(positionId, waypoint) {
                    this.debugWaypoint(waypoint, positionId)

                    if (this.animeShow(waypoint, positionId)) {
                        this.setCurrentPosition(positionId)
                        this.movePeople(positionId)
                        this.hidePeopleExcept(positionId)
                        setTimeout(() => {
                            this.showPeople(positionId)
                        }, 500)
                    }
                    if (this.animeHide(waypoint)) {
                        positionId = positionId - 1
                        this.setCurrentPosition(positionId)
                        this.movePeople(positionId)
                        this.hidePeopleExcept(positionId)
                        setTimeout(() => {
                            this.showPeople(positionId)
                        }, 500)
                    }
                },

                movePeople(positionId) {
                    if (!this.archetype.positionPeople.hasOwnProperty(this.journey.positionId)) {
                        console.error('Cannot Move People:', {archetype: this.myArchetype, positionId: this.journey.positionId})
                        return false
                    }

                    // This prevents other waypoints triggering things they shouldn't
                    if (this.journey.positionId === positionId) {
                        if (this.isFullUi()) {
                            this.movePeopleFullUi()
                        } else {
                            this.movePeopleCompactUi()
                        }
                    }
                },

                movePeopleFullUi() {
                    let svgWidth = window.innerWidth
                    if (svgWidth > 1020) {
                        svgWidth = 1020
                    }

                    let positionPeople = this.archetype.positionPeople[this.journey.positionId]
                    let height = 30
                    let yGap = 10
                    let xGap = 10
                    let yMargin = 30
                    let maxWidth = (svgWidth / 2) - (2 * yMargin)
                    if (maxWidth > 300) {
                        maxWidth = 300
                    }
                    let center = Math.round(svgWidth * .75)
                    let top = this.isTablet() ? 200 : 340

                    // Set up the rows, getting the total width so we can center everything.
                    for (let continent in positionPeople) {
                        let rows = []
                        let rowId = 0
                        let prevGroup = -1
                        let groupBreak = false
                        let rowTooWide = false

                        if (continent === 'us') {
                            center = Math.round(svgWidth * .25)
                        }

                        if (positionPeople.hasOwnProperty(continent)) {
                            for (let person of positionPeople[continent]) {
                                let width = this.anime.people[person].width
                                rows[rowId] = rows[rowId] || {width: 0, people: []}
                                rowTooWide = rows[rowId].width + width > maxWidth
                                groupBreak = this.anime.people[person].group > prevGroup
                                if (rowTooWide || groupBreak) {
                                    rowId++
                                    rows[rowId] = rows[rowId] || {width: 0, people: []}
                                }
                                rows[rowId].width += width + xGap
                                rows[rowId].people.push(person)
                                prevGroup = this.anime.people[person].group
                            }
                        }
                        for (let r = 0; r < rows.length; r++) {
                            let row = rows[r]
                            let xPos = center - (row.width / 2) + (xGap / 2)
                            for (let person of row.people) {
                                this.anime.people[person].x = xPos
                                xPos += this.anime.people[person].width + xGap
                                this.anime.people[person].y = top + (r * (height + yGap))
                            }
                        }
                    }
                },

                movePeopleCompactUi() {
                    let screenWidth = window.innerWidth
                    let svgHeight = 530
                    let footerHeight = this.isPhone() ? 100 : 140

                    let positionPeople = this.archetype.positionPeople[this.journey.positionId]
                    // The actual height is 24px but we move the background inside the svg so we don't need the gaps here
                    let height = 30
                    let yGap = 0
                    let xGap = 0
                    let xMargin = 6
                    let yMargin = 6

                    if (!this.isPhone()) {
                        yGap = 10
                        xGap = 10
                        xMargin = 20
                        yMargin = 20
                    }

                    let maxWidth = (screenWidth / 2) - (2 * xMargin)
                    let left = xMargin
                    let right = screenWidth - xMargin
                    let bottom = svgHeight - yMargin

                    // Set up the rows, getting the total width so we can center everything.
                    for (let continent in positionPeople) {
                        let rows = []
                        let rowId = 0
                        let prevGroup = -1
                        let groupBreak = false
                        let rowTooWide = false

                        // Position people into rows
                        if (positionPeople.hasOwnProperty(continent)) {
                            for (let person of positionPeople[continent]) {
                                let width = this.anime.people[person].width
                                rows[rowId] = rows[rowId] || {width: 0, people: []}
                                rowTooWide = rows[rowId].width + width > maxWidth
                                groupBreak = this.anime.people[person].group > prevGroup
                                if (rowTooWide || groupBreak) {
                                    rowId++
                                    rows[rowId] = rows[rowId] || {width: 0, people: []}
                                }
                                rows[rowId].width += width + xGap
                                rows[rowId].people.push(person)
                                prevGroup = this.anime.people[person].group
                            }
                        }

                        for (let r = 0; r < rows.length; r++) {
                            let row = rows[r]
                            let xPos = left
                            // eu side is now right aligned
                            if (continent === 'eu') {
                                xPos = right - row.width
                            }
                            for (let person of row.people) {
                                this.anime.people[person].x = xPos
                                xPos += this.anime.people[person].width + xGap
                                this.anime.people[person].y = bottom - ((rows.length - 1 - r) * (height + yGap)) - height
                            }
                        }

                        // Position the background gradients
                        if (continent === 'us') {
                            this.anime.peopleBackground1.x = 0
                            this.anime.peopleBackground1.width = (screenWidth / 2) - this.anime.peopleBackground1.x
                            this.anime.peopleBackground1.height = footerHeight + ((rows.length - 1) * (height + yGap)) + (2 * yMargin)
                            this.anime.peopleBackground1.y = svgHeight + footerHeight - this.anime.peopleBackground1.height
                            this.anime.peopleBackground1.opacity = 1
                        }
                        if (continent === 'eu') {
                            this.anime.peopleBackground2.x = (screenWidth / 2) + 1
                            this.anime.peopleBackground2.width = screenWidth - this.anime.peopleBackground2.x
                            this.anime.peopleBackground2.height = footerHeight + ((rows.length - 1) * (height + yGap)) + (2 * yMargin)
                            this.anime.peopleBackground2.y = svgHeight + footerHeight - this.anime.peopleBackground2.height
                            this.anime.peopleBackground2.opacity = 1
                        }
                    }

                    // Reset peopleBackgrounds if there is no data
                    if (!positionPeople.hasOwnProperty('us')) {
                        this.anime.peopleBackground1.x = 0
                        this.anime.peopleBackground1.width = (screenWidth / 2) - this.anime.peopleBackground1.x
                        this.anime.peopleBackground1.height = 0
                        this.anime.peopleBackground1.y = svgHeight + footerHeight
                        this.anime.peopleBackground1.opacity = 0
                    }
                    if (!positionPeople.hasOwnProperty('eu')) {
                        this.anime.peopleBackground2.x = (screenWidth / 2) + 1
                        this.anime.peopleBackground2.width = screenWidth - this.anime.peopleBackground2.x
                        this.anime.peopleBackground2.height = 0
                        this.anime.peopleBackground2.y = svgHeight + footerHeight
                        this.anime.peopleBackground2.opacity = 0
                    }
                },

                movePersonToPerson(movePerson, toPerson) {
                    // Center x alignment
                    this.anime.people[movePerson].x = this.anime.people[toPerson].x - ((this.anime.people[movePerson].width - this.anime.people[toPerson].width) / 2)
                    this.anime.people[movePerson].y = this.anime.people[toPerson].y
                },

                showPeople(positionId) {
                    // This prevents other waypoints triggering things they shouldn't
                    if (this.journey.positionId === positionId) {
                        if (!this.archetype.positionPeople.hasOwnProperty(this.journey.positionId)) {
                            console.error('Cannot Show People:', {archetype: this.myArchetype, positionId: this.journey.positionId})
                            return false
                        }
                        let positionPeople = this.archetype.positionPeople[this.journey.positionId]
                        for (let continent in positionPeople) {
                            if (positionPeople.hasOwnProperty(continent)) {
                                for (let person of positionPeople[continent]) {
                                    this.showPerson(person)
                                }
                            }
                        }
                    }
                },

                showPerson(person) {
                    if (!this.anime.people[person].active) {
                        this.anime.people[person].active = true
                        this.anime.people[person].hide.pause()
                        this.anime.people[person].show.restart()
                    }
                },

                hidePerson(person) {
                    if (this.anime.people[person].active) {
                        this.anime.people[person].active = false
                        this.anime.people[person].show.pause()
                        this.anime.people[person].hide.restart()
                    }
                },

                // Hide leadership titles
                showHideLeadershipTitles(waypoint) {
                    if (this.animeGoingIn(waypoint)) {
                        clearTimeout(this.timers.showHideLeadershipTitles[0])
                        this.showPerson('leadershipInEurope')
                        this.showPerson('leadershipInUs')
                    }

                    if (this.animeGoingOut(waypoint)) {
                        this.timers.showHideLeadershipTitles[0] = setTimeout(() => {
                            this.hidePerson('leadershipInEurope')
                            this.hidePerson('leadershipInUs')
                        }, 1000)
                    }
                },


                hidePeopleExcept(positionId) {
                    if (!this.archetype.positionPeople.hasOwnProperty(this.journey.positionId)) {
                        console.error('Cannot Hide People:', {archetype: this.myArchetype, positionId: this.journey.positionId})
                        return false
                    }

                    // This prevents other waypoints triggering things they shouldn't
                    if (this.journey.positionId === positionId) {
                        let positionPeople = this.archetype.positionPeople[this.journey.positionId]
                        let doNotHide = []
                        for (let continent in positionPeople) {
                            if (positionPeople.hasOwnProperty(continent)) {
                                for (let person of positionPeople[continent]) {
                                    doNotHide.push(person)
                                }
                            }
                        }

                        for (let person in this.anime.people) {
                            if (!doNotHide.includes(person)) {
                                if (this.anime.people[person].active) {
                                    this.anime.people[person].active = false
                                    this.anime.people[person].show.pause()
                                    this.anime.people[person].hide.restart()
                                }
                            }
                        }
                    }
                },

                hideAllPeople() {
                    for (let person in this.anime.people) {
                        if (this.anime.people[person].active) {
                            this.anime.people[person].active = false
                            this.anime.people[person].show.pause()
                            this.anime.people[person].hide.restart()
                        }
                    }
                },

                moveForeignHire(foreignHireId, personId) {
                    let person = this.anime.people[personId]
                    let x = person.x + (person.width / 2)
                    let y = person.y + (person.height / 2)

                    // No idea why it's 1+
                    this.anime.foreignHire[foreignHireId].x = 1 + Math.round(x)
                    this.anime.foreignHire[foreignHireId].y = 1 + Math.round(y)
                },
            },
        })
    },
}

export default {
    red: '#e42313',
    brandGrey0: '#665757',
    brandGrey1: '#8e8279',
    brandGrey2: '#bab0a8',
    brandGrey3: '#c6c4ba',
    brandGrey4: '#d8d1ca',
    brandGrey5: '#eeebe5',
    brandGrey6: '#f1efec',
    brandGrey7: '#f9f8f7',
    brandGrey8: '#fbf9fa',
    grey0: '#ffffff',
    grey20: '#DADADA',
    grey30: '#C4C4C4',
    grey40: '#AAAAAA',
    grey50: '#747474',
    grey60: '#5C5C5C',
    grey70: '#474747',
    grey80: '#343434',
    grey85: '#272727',
    grey90: '#181818',
    grey100: '#000000',
}
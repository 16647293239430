import Vue from 'vue'
import $ from 'jquery'
import onShareClick from '../../../../ui/js/utils/share'

let ivShare = Vue.component('iv-share', {
    // The parent app passes in the current route.url and route.title
    props: ['url', 'text', 'iconsOrText'],

    methods: {
        popup: function (popup) {
            onShareClick(popup, this.$props.text, this.$props.url)
        },
    },

    computed: {
        plainText: function () {
            return $('<p>' + this.$props.text + '</p>').text()
        },
    },

    template: '#iv-share',
})

export {ivShare}
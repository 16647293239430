export default [
    {
        'year': 1,
        'header': 'Seed',
        'title': 'Raise Seed from a local European investor',
        'revenue': 0,
        'headcount': 3,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'right',
        'displayTable': 'true',
        'personWaypoint': 'animePeopleAnchor1',
    },
    {
        'title': 'Build product from Europe, establishing product/market fit',
        'revenue': 0,
        'headcount': 6,
        'revenueUs': 0,
        'headcountUs': 0,
    },
    {
        'revenue': 0.5,
        'headcount': 8,
        'revenueUs': 0,
        'headcountUs': 0,
    },
    {
        'year': 2,
        'title': 'Focus on domestic customers. If you operate cross-border, localise in English',
        'revenue': 2,
        'headcount': 12,
        'revenueUs': 0,
        'headcountUs': 0,
    },
    {
        'header': 'Series A',
        'title': 'Raise Series A from European investor',
        'revenue': 6,
        'headcount': 30,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'right',
        'displayTable': 'true',
        'isSeriesAData': 'true',
    },
    {
        'year': 3,
        'title': 'Focus on European expansion for now, rather than on US',
        'revenue': 6,
        'headcount': 30,
        'revenueUs': 0,
        'headcountUs': 0,
    },
    {
        'title': 'Expand into an additional European market',
        'revenue': 10,
        'headcount': 60,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'left',
        'waypoint': 'animePulseOverUk',
        'personWaypoint': 'animePeopleAnchor2',
        'quoteText': 'The UK can be a great on-ramp to test product propositions and branding, before you take on the US',
        'quoteAuthor': 'Danny Rimer',
        'quoteCompany': 'Index Ventures',
        'addSectionClasses': 'pt0 no-quote-switch',
    },
    {
        'year': 4,
        'title': 'Raise Series B led by European investor with strong US network',
        'revenue': 15,
        'headcount': 150,
        'revenueUs': 0,
        'headcountUs': 0,
        'header': 'Series B',
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'Expand into further European markets and strengthen leadership team',
        'revenue': 20,
        'headcount': 150,
        'revenueUs': 0,
        'headcountUs': 0,
        'layout': 'left',
        'waypoint': 'animePulseOverFranceAndSpain',
        'personWaypoint': 'animePeopleAnchor3',
    },
    {
        'year': 5,
        'title': 'Research US market, with frequent visits. Create launch plan',
        'revenue': 25,
        'headcount': 200,
        'revenueUs': 0,
        'headcountUs': 0,
        'personWaypoint': 'animePeopleAnchor4',
        'waypoint': 'animeTravelLoopFromCeoX2',
    },
    {
        'header': 'Series C',
        'title': 'Raise large Series C, led by transatlantic investor',
        'revenue': 30,
        'headcount': 250,
        'revenueUs': 0,
        'headcountUs': 5,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'Send a landing team to launch the US, and support them on product',
        'quoteText': 'Your landing team needs to combine having an essential core skill-set with being a DNA-carrier for your culture. We typically see 2-4 individuals, senior & junior, with a commercial orientation',
        // 'why': 'You will need early US localisation to succeed',
        // todo: I think this needs to be like this on desktop
        //'layout': 'right',
        'personWaypoint': 'animePeopleAnchor5',
    },
    {
        'revenue': 40,
        'headcount': 325,
        'revenueUs': 1,
        'headcountUs': 8,
    },
    {
        'year': 6,
        'title': 'Leadership team in Europe, but likely to include one or two executives relocating from US',
        'revenue': 50,
        'headcount': 400,
        'revenueUs': 2,
        'headcountUs': 12,
        'quoteText': 'As they uplevelled their leadership, Farfetch always ran global searches including the US. The CPO and CMO both relocated from the US.',
        'personWaypoint': 'animePeopleAnchor6',
        'addSectionClasses': 'no-quote-switch',
    },
    {
        'year': 7,
        'header': 'Series D',
        'title': 'Raise Series D',
        'revenue': 60,
        'headcount': 500,
        'revenueUs': 3,
        'headcountUs': 15,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'title': 'Hire US President as traction becomes clear',
        'revenue': 80,
        'headcount': 600,
        'revenueUs': 5,
        'headcountUs': 35,
        'quoteText': 'Adyen searched for a heavyweight US president, and after a $250m Series B, hired Kamran Zaki, previously global payments lead at both Netflix and Paypal',
        // todo: I think this needs to be like this on desktop
        //'layout': 'right',
        'personWaypoint': 'animePeopleAnchor7',
    },
    {
        'year': 8,
        'title': 'Retain all other leadership in Europe. Founders remain in Europe',
        'revenue': 100,
        'headcount': 700,
        'revenueUs': 8,
        'headcountUs': 55,
    },
    {
        'header': 'Series E',
        'title': 'Raise Series E',
        'revenue': 120,
        'headcount': 800,
        'revenueUs': 12,
        'headcountUs': 80,
        'layout': 'left',
        'displayTable': 'true',
    },
    {
        'year': 9,
        'revenue': 160,
        'headcount': 1000,
        'revenueUs': 24,
        'headcountUs': 140,
        'title': 'If the right CTO/CPO candidates can no longer be found in Europe, relocate execs from US',
        'quoteText': 'There are fewer than 20 CTOs in Europe who have run 200+ people engineering teams at hypergrowth',
        'quoteAuthor': 'Dominic Jacquesson',
        'quoteCompany': 'Index Ventures',
        'personWaypoint': 'animePeopleAnchor8',
        'addSectionClasses': 'no-quote-switch',
    },
    {
        'title': 'List in Europe (maybe in US)',
        'revenue': 200,
        'headcount': 1200,
        'revenueUs': 40,
        'headcountUs': 200,
        'quoteText': 'More than 50% of Adyen&rsquo;s institutional shareholders are US-based. It proves that for a great business, exchange location doesn&rsquo;t matter',
        'quoteAuthor': 'Jan Hammer',
        'quoteCompany': 'Index Ventures',
        'header': 'Listing',
        'layout': 'left',
        'displayTable': 'true',
        'isListingData': 'true',
        'waypoint': 'animeFireworks',
    },
]
import quiz from './quiz.data'
import decisionTree from './decisionTree.data'
import anchor from './anchor.data'
import compass from './compass.data'
import pendulum from './pendulum.data'
import telescope from './telescope.data'
import colors from './colors.data'
import anime from './anime.data'


/*
    This is the correct order for people
    1. Founders (keep in single line as far as possible vs screen res/width)
        founderCeo
        founderCto
    2. CXO's (keep in single line as far as possible vs screen res/width)
        cto
        cpo
        cro
        cmo
        cfo
    3. VP's
        vpEngineering
        vpProduct
        vpSales
        vpBizDev
        vpGrowth
        vpMarketing
        vpInsideSales
        vpCustomerSuccess
        vpCustomerSupport
    4. Other
        productDirector
        gmEMEA
        gmEurope
        gmFrance
        gmSpain
        gmUk
 */
export default {
    bodyEl: '',
    colors: colors,
    route: {
        title: 'Archetypes for Expansion',
        url: '',
    },
    quiz: quiz,
    decisionTree: decisionTree,
    activeYear: 1,
    trackedAnswers: {},
    forcedArchetype: '',
    archetypes: {
        anchor: {
            sections: anchor,
            scrollData: [],
            years: {},
            positionPeople: {
                0: {},
                1: {
                    'eu': [
                        'leadershipInEurope',
                        'founderCeo',
                        'founderCto',
                    ],
                    'us': [
                        'leadershipInUs',
                    ],
                },
                2: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'vpSales',
                        'vpMarketing',
                        'gmUk',
                    ],
                },
                3: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cro',
                        'vpEngineering',
                        'vpSales',
                        'vpMarketing',
                        'gmUk',
                        'gmFrance',
                        'gmSpain',
                    ],
                },
                4: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cro',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmUk',
                        'gmFrance',
                        'gmSpain',
                    ],
                },
                5: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cro',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmUk',
                        'gmFrance',
                        'gmSpain',
                    ],
                    'us': [
                        'landingTeam',
                    ],
                },
                6: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cro',
                        'cmo',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmEurope',
                    ],
                    'us': [
                        'landingTeam',
                    ],
                },
                7: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cro',
                        'cmo',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmEurope',
                    ],
                    'us': [
                        'usPresident',
                    ],
                },
                8: {
                    'eu': [
                        'founderCeo',
                        'cto',
                        'cpo',
                        'cro',
                        'cmo',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmEurope',
                    ],
                    'us': [
                        'usPresident',
                    ],
                },
            },
        },
        compass: {
            sections: compass,
            scrollData: [],
            years: {},
            positionPeople: {
                0: {},
                1: {
                    'eu': [
                        'leadershipInEurope',
                        'founderCeo',
                        'founderCto',
                    ],
                    'us': [
                        'leadershipInUs',
                    ],
                },
                2: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'productDirector',
                    ],
                },
                3: {
                    'eu': [
                        'founderCeoSplit2',
                        'founderCto',
                        'productDirector',
                    ],
                    'us': [
                        'founderCeoSplit',
                    ],
                },
                4: {
                    'eu': [
                        'founderCeoSplit2',
                        'founderCto',
                        'productDirector',
                    ],
                    'us': [
                        'founderCeoSplit',
                        'vpSales',
                    ],
                },
                5: {
                    'eu': [
                        'founderCeoSplit2',
                        'founderCto',
                        'productDirector',
                    ],
                    'us': [
                        'founderCeoSplit',
                        'cro',
                        'cmo',
                        'vpSales',
                    ],
                },
                6: {
                    'eu': [
                        'founderCto',
                        'productDirector',
                    ],
                    'us': [
                        'founderCeo',
                        'cro',
                        'cmo',
                        'vpSales',
                    ],
                },
                7: {
                    'eu': [
                        'founderCto',
                        'vpEngineering',
                        'vpProduct',
                    ],
                    'us': [
                        'founderCeo',
                        'cro',
                        'cmo',
                        'cfo',
                        'vpSales',
                    ],
                },
                8: {
                    'eu': [
                        'founderCto',
                        'vpEngineering',
                        'vpProduct',
                    ],
                    'us': [
                        'founderCeo',
                        'cro',
                        'cmo',
                        'cfo',
                        'vpSales',
                        'vpCustomerSuccess',
                    ],
                },
                9: {
                    'eu': [
                        'founderCto',
                        'vpEngineering',
                        'vpProduct',
                        'gmEMEA',
                    ],
                    'us': [
                        'founderCeo',
                        'cro',
                        'cmo',
                        'cfo',
                        'vpSales',
                        'vpCustomerSuccess',
                    ],
                },
                10: {
                    'eu': [
                        'vpEngineering',
                        'vpProduct',
                        'gmEMEA',
                    ],
                    'us': [
                        'founderCeo',
                        'cto',
                        'cpo',
                        'cro',
                        'cmo',
                        'cfo',
                        'vpSales',
                        'vpCustomerSuccess',
                    ],
                },
            },
        },
        pendulum: {
            sections: pendulum,
            scrollData: [],
            years: {},
            positionPeople: {
                0: {},
                1: {
                    'eu': [
                        'leadershipInEurope',
                        'founderCeo',
                        'founderCto',
                    ],
                    'us': [
                        'leadershipInUs',
                    ],
                },
                2: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'vpSales',
                        'vpMarketing',
                    ],
                },
                3: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'vpSales',
                        'vpMarketing',
                    ],
                    'us': [
                        'landingTeam',
                    ],
                },
                4: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cro',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                    ],
                    'us': [
                        'landingTeam',
                    ],
                },
                5: {
                    'eu': [
                        'founderCeoSplit2',
                        'founderCto',
                        'cro',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                    ],
                    'us': [
                        'founderCeoSplit',
                        'landingTeam',
                    ],
                },
                6: {
                    'eu': [
                        'founderCeoSplit2',
                        'founderCto',
                        'cro',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmEurope',
                    ],
                    'us': [
                        'founderCeoSplit',
                        'usPresident',
                    ],
                },
                7: {
                    'eu': [
                        'founderCeoSplit2',
                        'founderCto',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmEurope',
                    ],
                    'us': [
                        'founderCeoSplit',
                        'cro2',
                        'cmo',
                        'usPresident',
                    ],
                },
                8: {
                    'eu': [
                        'founderCeoSplit2',
                        'founderCto',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmEurope',
                    ],
                    'us': [
                        'founderCeoSplit',
                        'cro2',
                        'cmo',
                        'usPresident',
                    ],
                },
                9: {
                    'eu': [
                        'founderCeoSplit2',
                        'cto',
                        'cpo',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpSales',
                        'vpMarketing',
                        'vpCustomerSuccess',
                        'gmEurope',
                    ],
                    'us': [
                        'founderCeoSplit',
                        'cro2',
                        'cmo',
                        'usPresident',
                    ],
                },
            },
        },
        telescope: {
            sections: telescope,
            scrollData: [],
            years: {},
            positionPeople: {
                0: {},
                1: {
                    'eu': [
                        'leadershipInEurope',
                        'founderCeo',
                        'founderCto',
                    ],
                    'us': [
                        'leadershipInUs',
                    ],
                },
                2: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'vpMarketing',
                    ],
                },
                3: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'vpMarketing',
                    ],
                    'us': [
                        'vpBizDev',
                    ],
                },
                4: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpGrowth',
                        'vpMarketing',
                    ],
                    'us': [
                        'vpBizDev',
                    ],
                },
                5: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpGrowth',
                        'vpMarketing',
                    ],
                    'us': [
                        'vpBizDev',
                        'vpInsideSales',
                    ],
                },
                6: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpMarketing',
                    ],
                    'us': [
                        'vpBizDev',
                        'vpInsideSales',
                    ],
                },
                7: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cmo',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpGrowth',
                        'vpMarketing',
                    ],
                    'us': [
                        'vpBizDev',
                        'vpInsideSales',
                        'vpCustomerSupport',
                    ],
                },
                8: {
                    'eu': [
                        'founderCeo',
                        'founderCto',
                        'cmo',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpGrowth',
                        'vpMarketing',
                    ],
                    'us': [
                        'cro',
                        'vpBizDev',
                        'vpInsideSales',
                        'vpCustomerSuccess',
                        'vpCustomerSupport',
                    ],
                },
                9: {
                    'eu': [
                        'founderCeo',
                        'cto',
                        'cpo',
                        'cmo',
                        'cfo',
                        'vpEngineering',
                        'vpProduct',
                        'vpGrowth',
                        'vpMarketing',
                    ],
                    'us': [
                        'cro',
                        'vpBizDev',
                        'vpInsideSales',
                        'vpCustomerSuccess',
                        'vpCustomerSupport',
                    ],
                },
            },
        },
    },
    scrollTop: 0,
    journey: {
        el: '.archetypes__journey',
        yPos: 0,
        positionId: 0,
    },
    modal: {
        show: false,
        text: '',
    },
    intersectionOptions: {
        root: null,
        rootMargin: '0px 0px -20% 0px',
        threshold: 1,
    },
    waypoints: {
        isActive: false,
    },
    yearWaypoints: {
        isActive: true,
    },
    anime: anime,
    timers: {
        showHideLeadershipTitles: [''],
    },
}
